import React from "react";
import { Flex, useMediaQuery, Text, HStack, Link } from "@chakra-ui/react";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BiLinkExternal } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
import ScrollBar from "../../other/Scrollbar";

const CaseClinicalHistory = ({ caseInfo, setTabIndex }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLessthan1450] = useMediaQuery("(max-width:1450px)");
  return (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between" mb="5px">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Clinical history
        </Text>
        <BiLinkExternal
          size={18}
          style={{ color: "#1B75BC", cursor: "pointer" }}
          onClick={() => setTabIndex(2)}
        />
      </HStack>

      {caseInfo?.clinicalHistory?.docsUrl?.filter(
        (obj) => obj.isDeleted === false
      ).length > 0 ? (
        <Flex w="100%" h="95%" direction="column">
          <ScrollBar>
            {caseInfo?.clinicalHistory?.docsUrl
              ?.filter((obj) => obj.isDeleted === false)
              .map((url) => {
                return (
                  <Flex
                    key={uuidv4()}
                    minW="50%"
                    minH="70px"
                    h="fit-content"
                    mr="17px"
                    mb="11.5px"
                    background="#F9F9F9"
                    boxShadow="0px 1px 2px rgba(176, 200, 214, 0.08)"
                    borderRadius="10px"
                    p="4px 8px"
                    alignItems="center"
                  >
                    <HStack w="100%">
                      <HiOutlineClipboardList color="#1B75BC" size={24} />
                      <Flex direction="column" w="90%">
                        <HStack w="100%" justifyContent="space-between">
                          <Text
                            fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            w="75%"
                          >
                            {url?.docName}
                          </Text>
                          <Link href={url?.docUrl} isExternal w="30%">
                            <Flex alignItems="center" justifyContent="flex-end">
                              <Text
                                color="#1B75BC"
                                textDecoration="underline"
                                cursor="pointer"
                                fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                View document
                              </Text>
                            </Flex>
                          </Link>
                        </HStack>
                        <Text
                          fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                          mt="5px"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {url?.docDescription}
                        </Text>
                      </Flex>
                    </HStack>
                  </Flex>
                );
              })}
          </ScrollBar>
        </Flex>
      ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Text opacity="0.3">No documents uploaded</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default CaseClinicalHistory;
