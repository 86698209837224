import React, { useEffect, useState } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Link, Flex } from "@chakra-ui/react";
import { useLazyGetSynopticReportQuery } from "../../state/API/HospitalApi";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "1.5vh 0vw",
  },
  tableBox: {
    minWidth: "400px",
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    padding: "0.5vh 2.5vw",
  },
  tableColumn: {
    minWidth: "33%",
    maxWidth: "33%",
    fontSize: "12px",
    height: "35px",
  },
  tableData: {
    display: "flex",
    flexDirection: "column",
  },
  tableDataLabel: {
    paddingLeft: "0.5208vw",
    paddingRight: "10px",
    marginTop: "5px",
  },
  tableDataLabelInfo: {
    padding: "0.7vh 0.5208vw",
    color: "#8F8F8F",
  },
  reportBox: {
    marginTop: "1.7vh",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0vh 2.5vw",
  },
  reportHeadline: {
    fontWeight: 600,
    fontSize: "12px",
  },
  title: {
    fontWeight: 600,
    fontSize: "12px",
    paddingBottom: "1vh",
    paddingTop: "1vh",
    paddingLeft: "1vw",
    width: "100%",
    backgroundColor: "#F7FBFD",
    margin: "0.6vh 0vw",
  },
  reportColumn: {
    minWidth: "45%",
    paddingTop: "12px",
    display: "flex",
    flexDirection: "column",
  },
  reportData: {
    fontWeight: "200",
    fontSize: "11px",
    paddingTop: "4px",
    color: "#8F8F8F",
    minHeight: "15px",
  },
  media: {
    width: "100%",
    paddingTop: "2vh",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  image: {
    width: "50px",
    height: "50px",
  },
  reporterBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "0vh 2.5vw",
  },
  reporterName: {
    color: "#3B5D7C",
    fontSize: "12px",
  },
  reporterDetails: {
    fontSize: "12px",
    paddingTop: "6px",
  },
  header: {
    height: "6vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "1vh 0vw",
    marginTop: "-1.5vh",
    backgroundColor: "#F7FBFD",
    borderBottom: "1px solid #8F8F8F",
    width: "100%",
    fontSize: "14px",
    marginLeft: "2vw",
  },
});

const Report = ({ caseInfo, userInfo, response }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>LYMPHOMA REPORT</Text>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>PATIENT NAME</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.patientName?.firstName}`}
                {`${caseInfo?.patient?.patientName?.lastName}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>MRN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.uhid}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>AGE</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.age?.years
                    ? caseInfo?.patient?.age?.years
                    : ""
                }`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>GENDER</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.gender ? caseInfo?.patient?.gender : ""
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>CLINICIAN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.treatingDoctor}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.reportBox}>
          <Text style={styles.title}>
            CLINICAL INFORMATION & SURGICAL HANDLING
          </Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>TYPES OF SPECIMEN</Text>
            <Text style={styles.reportData}>{response?.typeOfSpecimen}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DATE OF REQUEST</Text>
            <Text style={styles.reportData}>{response?.dateOfRequest}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PRINCIPAL CLINICIAN</Text>
            <Text style={styles.reportData}>
              {response?.sprincipalClinician}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SITE OF BIOPSY</Text>
            <Text style={styles.reportData}>{response?.siteOfBiopsy}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LATERABILITYE</Text>
            <Text style={styles.reportData}>{response?.laterability}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>REASON FOR BIOPSY</Text>
            <Text style={styles.reportData}>{response?.reasonForBiopsy}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              CLINICAL OR DIFFERENT DIAGNOSIS
            </Text>
            <Text style={styles.reportData}>{response?.specimenWeight}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              WHOLE TUMOUR (DCIS + INVASIVE) SIZE MM
            </Text>
            <Text style={styles.reportData}>{response?.wholeTumourSize}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>GRADE</Text>
            <Text style={styles.reportData}>{response?.grade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              INVOLVED SITE OR PATTERN OF DISEASE SPREAD
            </Text>
            <Text style={styles.reportData}>
              {response?.involvedSiteOrPatternOfDiseasesSpread}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              STAGES OR CLINICAL EXTENT OF DISEASES
            </Text>
            <Text style={styles.reportData}>
              {response?.clinicalOrStagesExtentOfDiseases}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>CONSTITUTIONAL SYMPTOMS</Text>
            <Text style={styles.reportData}>
              {response?.constitutionalSymptoms}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              FURTHER CLINICAL INFORMATION
            </Text>
            <Text style={styles.reportData}>
              {response?.furtherClinicalInformation}
            </Text>
          </View>

          <Text style={styles.title}>MICROSCOPIC FINDING</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SPECIMEN TYPE</Text>
            <Text style={styles.reportData}>{response?.specimenType}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SPECIMEN SIZE</Text>
            <Text style={styles.reportData}>{response?.specimenSize}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              NARRATIVE OR MICROSCOPIC DESCRIPTION
            </Text>
            <Text style={styles.reportData}>
              {response?.narrativeOrMicroscopicDescription}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ABNORMAL CELLS:PATTERNS OF INFILTRATION OR ARCHITECTURE
            </Text>
            <Text style={styles.reportData}>{response?.abnormalCells}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ABNORMAL CELL SIZE</Text>
            <Text style={styles.reportData}>{response?.abnormalCellSize}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ABNORMAL CELL CYTOMORPHOLOGY
            </Text>
            <Text style={styles.reportData}>
              {response?.abnormalCellCytomorphology}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ABNORMAL CELL PROLIFERATIVE INDICATORS
            </Text>
            <Text style={styles.reportData}>
              {response?.abnormalCellProLiferativeIndicators}
            </Text>
          </View>

          <Text style={styles.title}>ANCILLARY TEST FINDINGS</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>IMMUNOHISTOCHEMISTRY</Text>
            <Text style={styles.reportData}>
              {response?.immunoHistoChemistry}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>FLOW STUDIES</Text>
            <Text style={styles.reportData}>{response?.flowStudies}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>CLONALITY</Text>
            <Text style={styles.reportData}>{response?.clonality}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>WHO DISEASE SUBTYPE</Text>
            <Text style={styles.reportData}>{response?.whoDiseaseSubType}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ABNORMAL CELL SIZE</Text>
            <Text style={styles.reportData}>
              {response?.ancillaryAbnormalCellSize}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ABNORMAL CELL CYTOMORPHOLOGY
            </Text>
            <Text style={styles.reportData}>
              {response?.ancillaryCytomorphology}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              ABNORMAL CELL PROLIFERATIVE INDICATORS
            </Text>
            <Text style={styles.reportData}>
              {response?.ancillaryProLiferativeIndicators}
            </Text>
          </View>
        </View>
        <View style={styles.reporterBox}>
          <Text
            style={styles.reporterName}
          >{`Dr. ${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
          <Text style={styles.reporterDetails}>
            {userInfo?.highestDegree?.split("")?.join(".")?.toUpperCase()}
          </Text>
          <Text style={styles.reporterDetails}>{`${
            userInfo?.organization?.name
          }, ${
            userInfo?.organization?.address
              ? userInfo?.organization?.address
              : ""
          }`}</Text>
        </View>
      </Page>
    </Document>
  );
};

const Lymphoma = ({
  caseInfo,
  slideInfo,
  userInfo,
  fetchSynopticReport,
  setReportLinkData,
}) => {
  const [fetchReportData] = useLazyGetSynopticReportQuery();
  const [response, setResponse] = useState("");
  useEffect(() => {
    async function fetchReport() {
      if (caseInfo?._id) {
        const resp = await fetchReportData({
          reportType: "lymphoma-cancer",
          caseId: caseInfo?._id,
        });
        setResponse(resp?.data);
      }
    }
    fetchReport();
  }, [fetchSynopticReport]);

  return (
    <Flex direction="column" w="100%" h="100%" alignItems="center">
      <Flex
        overflowY="scroll"
        overFlowX="clip"
        h="90%"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        bgColor="#fff"
      >
        <Report
          caseInfo={caseInfo}
          slideInfo={slideInfo}
          userInfo={userInfo}
          response={response?.data}
        />
      </Flex>
      <PDFDownloadLink
        document={
          <Report
            caseInfo={caseInfo}
            slideInfo={slideInfo}
            userInfo={userInfo}
            response={response?.data}
          />
        }
        fileName="case_report_lymphoma.pdf"
      >
        {/* {({ url }) => (
          <Link href={url} isExternal style={{ color: "#1B75BC" }}>
            Download lymphoma report
          </Link>
        )} */}
        {({ url }) => setReportLinkData(url)}
      </PDFDownloadLink>
    </Flex>
  );
};

export default Lymphoma;
