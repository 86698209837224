import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const ComingSoon = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width="40vw" height="30vh">
        <ModalCloseButton />
        <ModalBody
          fontWeight="700"
          fontSize="3vw"
          textAlign="center"
          verticalAlign="middle"
          lineHeight="15vw"
          color="light.700"
        >
          Coming Soon
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ComingSoon;
