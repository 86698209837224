import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";
import {
  useAddUserToCaseMutation,
  useGetPathologistListQuery,
} from "../state/API/HospitalApi";

const AssignCase = ({ caseId, userInfo }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { data: pathologistList } = useGetPathologistListQuery({
    organizationId: userInfo?.organization?._id,
  });
  const [addUser] = useAddUserToCaseMutation();
  const { user } = useAuth0();
  const [confirmModal, setConfirmModal] = useState(false);
  const [pathologistName, setPathologistName] = useState("");
  const [pathologistId, setPathologistId] = useState("");
  const toast = useToast();

  const handleModal = (firstName, lastName, id) => {
    setConfirmModal(true);
    setPathologistName(`${firstName} ${lastName}`);
    setPathologistId(id);
  };
  const handleAddMember = async () => {
    setConfirmModal(false);
    try {
      await addUser({
        userId: pathologistId,
        caseId,
        subClaim: user?.sub,
      });
      toast({
        title: "Success",
        description: `Case assigned to Dr.${pathologistName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Error",
        description: `Some error while assigning case`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <VStack
        alignItems="flex-start"
        w="100%"
        fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
      >
        {pathologistList?.map((pathologist) => {
          return `${pathologist.firstName}${pathologist.lastName}` !==
            `${userInfo.firstName}${userInfo.lastName}` ? (
            <HStack
              key={pathologist?._id}
              borderBottom="1px solid #3B5D7C"
              _last={{ borderBottom: "none" }}
              w="100%"
              px="1.1vw"
              py="0.625vh !important"
              maxH="6vh"
              mt="-0rem !important"
              onClick={() =>
                handleModal(
                  pathologist?.firstName,
                  pathologist?.lastName,
                  pathologist?._id
                )
              }
              _hover={{ bg: "light.900" }}
            >
              <Avatar size="sm" />
              <Text>
                Dr. {pathologist?.firstName} {pathologist?.lastName}
              </Text>
            </HStack>
          ) : null;
        })}
      </VStack>
      <Modal
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        size="6xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent background="light.200" w="fit-content" borderRadius="0px">
          <ModalBody
            flexDirection="column"
            px="2.1vw"
            fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
          >
            <HStack mt="2vh">
              <BsQuestionCircleFill color="#3B5D7C" size={20} />
              <Text>
                {`Are you sure you want to assign case to Dr.${pathologistName}`}
              </Text>
            </HStack>
            <HStack justifyContent="flex-end" mt="3.6vh" mb="2vh">
              <Button
                borderRadius="0"
                border="1px solid #3B5D7C"
                bg="light.100"
                color="light.700"
                h="3.05vh"
                w="4.375vh"
                minW="84px"
                minH="30px"
                fontWeight="500"
                _focus={{ outline: "none" }}
                onClick={() => setConfirmModal(false)}
                mr="0.7vw"
                fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
              >
                Cancel
              </Button>
              <Button
                borderRadius="0"
                border="1px solid #3B5D7C"
                bg="light.700"
                h="3.05vh"
                w="4.375vh"
                minW="84px"
                minH="30px"
                color="light.100"
                fontWeight="500"
                _focus={{ outline: "none" }}
                onClick={handleAddMember}
                fontSize={ifWidthLessthan1920 ? "14px" : "16px"}
              >
                Ok
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignCase;
