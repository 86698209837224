import React, { useEffect, useState } from "react";
import { Flex, useToast, useMediaQuery, Spinner } from "@chakra-ui/react";

import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  useGetUserInfoQuery,
  useGetFilterDataQuery,
  useLazyGetCaseInfoQuery,
  useGetSharedCasesQuery,
} from "./state/API/HospitalApi";
import { resetNewCase } from "./state/Reducers/newCaseReducer";
import Loading from "./Components/Loading/loading";
import TotalCases from "./Components/NewDashboard/TotalCases";
import UserStatistics from "./Components/NewDashboard/UserStatistics";
import Header from "./DashboardLayout/Header";
import { onMessageListener } from "./Firebase/init";

const AppContainer = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  dispatch(resetNewCase());
  const [doctorList, setDoctorList] = useState([]);
  const [organList, setOrganList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [caseStatus, setCaseStatus] = useState("");
  const [getCaseInfo] = useLazyGetCaseInfoQuery();

  const { data: filterData, isFetching } = useGetFilterDataQuery(
    {
      organizationId: userInfo?.organization?._id,
      doctor: doctorList.length === 0 ? "" : doctorList,
      organ: organList.length === 0 ? "" : organList,
      hospital: hospitalList.length === 0 ? "" : hospitalList,
      caseStatus,
    },
    { skip: !userInfo?.organization?._id }
  );
  const { data: sharedCaseData } = useGetSharedCasesQuery({
    userId: userInfo?._id,
  });

  localStorage.setItem("department", "allCases");

  const [department, setDepartment] = useState(
    localStorage.getItem("department")
  );
  // search bar state values
  const [pageNumber, setPageNumber] = useState(1);
  const [searchInput, setSearchInput] = useState();
  const [searchResponse, setSearchResponse] = useState([]);
  //

  const toast = useToast();
  // console.log(JSON.stringify(caseInfoData));
  useEffect(() => {
    localStorage.setItem("page", "cases");

    localStorage.setItem("department", "allCases");
    if (localStorage.getItem("depart")) {
      setDepartment(localStorage.getItem("depart"));
      setTimeout(localStorage.removeItem("depart"), 2000);
    }
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [department]);

  // onMessageListener()
  // .then((payload) => {
  //   if (payload.notification) {
  //     const { title, body } = payload.notification;
  //     toast({
  //       title: title || "Notification",
  //       description: body || "",
  //       status: "success",
  //       duration: 2000,
  //       isClosable: true,
  //     });
  //   }
  //   if (payload.data && payload.data.caseId) {
  //     getCaseInfo({ caseId: payload.data.caseId }).unwrap();
  //   }
  // })
  // .catch((err) => {
  //   toast({
  //     title: "Server Error",
  //     description: err.message,
  //     status: "error",
  //     duration: 2000,
  //     isClosable: true,
  //   });
  //   console.error(err);
  // });

  return isLoading ? (
    <Loading />
  ) : (
    <Flex h="100vh" w="100vw" flexDirection="column" overflow="hidden">
      {isFetching ? (
        <Flex
          zIndex={1000}
          position="absolute"
          bgColor="#fff"
          opacity="0.5"
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" color="#000" />
        </Flex>
      ) : null}

      <Header
        userInfo={userInfo}
        searchResponse={searchResponse}
        setSearchResponse={setSearchResponse}
        department={department}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />

      <Flex
        w="100%"
        h="100vh"
        justifyContent="space-between"
        flex="1"
        minH={0}
        direction="column"
      >
        <Flex
          w="100%"
          h="100%"
          // p={ifWidthLessthan1920 ? "15px 25px" : "15px 45px"}
          p={ifWidthLessthan1920 ? "25px 25px 7px 25px" : "15px 45px"}
          direction="column"
        >
          <UserStatistics
            filterData={filterData}
            setCaseStatus={setCaseStatus}
          />

          <Flex
            w="100%"
            h="100%"
            // p={ifWidthLessthan1920 ? "15px 15px" : "15px 45px"}
            p={ifWidthLessthan1920 ? "15px 10px 0px 3.5px" : "15px 45px"}
            direction="column"
          >
            <TotalCases
              userInfo={userInfo}
              filterData={filterData}
              setDoctorList={setDoctorList}
              setOrganList={setOrganList}
              isLoading={isLoading}
              searchResponse={searchResponse}
              searchInput={searchInput}
              sharedCaseData={sharedCaseData}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AppContainer;
