/* eslint-disable no-else-return */
import React, { useState } from "react";
import {
  Button,
  Flex,
  HStack,
  Input,
  Text,
  useMediaQuery,
  VStack,
  Select,
  useToast,
  InputGroup,
  InputRightElement,
  Image,
  Textarea,
  Spinner,
  Box,
} from "@chakra-ui/react";

import { MdOutlineVisibility } from "react-icons/md";
import { AiOutlineEyeInvisible, AiOutlineClose } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import generator from "generate-password";
import axios from "axios";
import Environment from "../../environment";
import { useAddUserToOrgMutation } from "../../state/API/HospitalApi";
import uploadSignature from "../../Static/Images/uploadSignature.svg";

const MemberDetails = () => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  const [addUser] = useAddUserToOrgMutation();
  const id = "test-toast";

  const generatePassword = () => {
    const randomPassword = generator.generate({
      length: 12,
      strict: true,
      symbols: true,
      numbers: true,
      exclude: '()+_-=}{[]|:;"/?.><,`~',
    });
    return randomPassword;
  };
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    contact: "",
    dob: "",
    gender: "",
    designation: "",
    exp: "",
    qualification: "",
    registration: "",
    address: "",
    password: generatePassword(),
    signature: "",
  });
  const [show, setShow] = useState(false);

  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameMessageError, setFirstNameMessageError] = useState("");

  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameMessageError, setLastNameMessage] = useState("");

  const [designationError, setDesignationError] = useState(false);
  const [designationMessageError, setDesignationMessage] = useState("");

  const [contactError, setContactError] = useState(false);
  const [contactMessageError, setContactMessage] = useState("");

  const [emailError, setEmailError] = useState(false);
  const [emailMessageError, setEmailMessage] = useState("");

  const [registrationError, setRegistrationError] = useState(false);
  const [registrationMessageError, setRegistrationMessage] = useState("");

  const [dobError, setDobError] = useState(false);
  const [dobMessageError, setDobMessage] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [passwordMessageError, setPasswordMessage] = useState("");

  const [genderError, setGenderError] = useState(false);
  const [genderMessageError, setGenderMessage] = useState("");

  const [experienceError, setExperienceError] = useState(false);
  const [experienceMessageError, setExperienceMessage] = useState("");

  const [qualificationError, setQualificationError] = useState(false);
  const [qualificationMessageError, setQualificationMessage] = useState("");

  const [imageUploadError, setImageUploadError] = useState(false);
  const [imageUploadMessageError, setImageUploadMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    if (e.target.name === "firstName") {
      const letters = /^[A-Za-z][A-Za-z ]+$/;
      if (e.target.value.length < 1) {
        setFirstNameError(false);
      } else if (!e.target.value.match(letters)) {
        setFirstNameError(true);
        setFirstNameMessageError("First name must be alphabetical");
      } else if (e.target.value.length < 3 || e.target.value.length > 35) {
        setFirstNameError(true);
        setFirstNameMessageError("First name must be minimum 3 and maximum 35");
      } else {
        setFirstNameError(false);
      }
    }
    if (e.target.name === "lastName") {
      const letters = /^[A-Za-z][A-Za-z ]+$/;
      if (e.target.value.length < 1) {
        setLastNameError(false);
      } else if (!e.target.value.match(letters)) {
        setLastNameError(true);
        setLastNameMessage("Last name must be alphabetical");
      } else if (e.target.value.length < 3 || e.target.value.length > 35) {
        setLastNameError(true);
        setLastNameMessage("Last name must be minimum 3 and maximum 35");
      } else {
        setLastNameError(false);
      }
    }
    if (e.target.name === "designation") {
      const letters = /^[A-Za-z][A-Za-z ]+$/;
      if (e.target.value.length < 1) {
        setDesignationError(false);
      } else if (e.target.value.length < 3 || e.target.value.length > 45) {
        setDesignationError(true);
        setDesignationMessage(
          "Designation must minimum 3 and maximum 45 characters"
        );
      } else if (!e.target.value.match(letters)) {
        setDesignationError(true);
        setDesignationMessage("Designation must be alphabetical");
      } else {
        setDesignationError(false);
      }
    }
    if (e.target.name === "contact") {
      const letters = /^\d+$/;
      if (e.target.value.length < 1) {
        setContactError(false);
      } else if (!e.target.value.match(letters)) {
        setContactError(true);
        setContactMessage("Contact must be numeric");
      } else if (e.target.value.length < 10) {
        setContactError(true);
        setContactMessage("Length of contact number cannot be less than 10");
      } else {
        setContactError(false);
      }
    }
    if (e.target.name === "emailAddress") {
      const pattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[ ]*$/;
      if (e.target.value.length < 1) {
        setEmailError(false);
      } else if (!e.target.value.match(pattern)) {
        setEmailError(true);
        setEmailMessage(
          "Email should be of following format: email@domain.com"
        );
      } else {
        setEmailError(false);
      }
    }
    if (e.target.name === "registration") {
      const pattern = /^[a-zA-Z0-9]+$/;
      if (e.target.value.length < 1) {
        setRegistrationError(false);
      } else if (e.target.value.length < 3 || e.target.value.length > 14) {
        setRegistrationError(true);
        setRegistrationMessage(
          "Registration must minimum 3 and maximum 14 characters"
        );
      } else if (!e.target.value.match(pattern)) {
        setRegistrationError(true);
        setRegistrationMessage("Registration must be Alphanumeric");
      } else {
        setRegistrationError(false);
      }
    }
    if (e.target.name === "dob") {
      const today = new Date();
      const date = new Date(e.target.value);
      const difference = (today - date) / (1000 * 60 * 60 * 24 * 365);
      if (difference < 18) {
        setDobError(true);
        setDobMessage("User should be of atleast 18 years");
      } else {
        setDobError(false);
      }
    }
    if (e.target.name === "password") {
      const regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;

      if (e.target.value.length < 1) {
        setPasswordError(false);
      } else if (e.target.value.length < 8 || e.target.value.length > 16) {
        setPasswordError(true);
        setPasswordMessage(
          "Password must be minimum 8 and maximum 16 character long, a capital charater, a lowercase character, a special symbol and a number."
        );
      } else if (!e.target.value.match(regularExpression)) {
        setPasswordError(true);
        setPasswordMessage(
          "Password must be minimum 8 and maximum 16 character long, a capital charater, a lowercase character, a special symbol and a number"
        );
      } else {
        setPasswordError(false);
      }
    }
    if (e.target.name === "gender") {
      if (e.target.value === "") {
        setGenderError(true);
        setGenderMessage("This Field Required");
      } else {
        setGenderError(false);
      }
    }
    if (e.target.name === "qualification") {
      if (e.target.value === "") {
        setQualificationError(true);
        setQualificationMessage("This Field Required");
      } else {
        setQualificationError(false);
      }
    }
    if (e.target.name === "exp") {
      if (e.target.value === "") {
        setExperienceError(true);
        setExperienceMessage("This Field Required");
      } else {
        setExperienceError(false);
      }
    }

    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const onSelectFile = async (e) => {
    if (e.target.name === "signature") {
      if (e.target.files === "") {
        setImageUploadError(true);
        setImageUploadMessage("This Field Required");
      } else if ((e.target.files[0].size / (1024 * 1024)).toFixed(2) >= 3) {
        setImageUploadError(true);
        setImageUploadMessage("File is greater than 3MB");
      } else {
        setImageUploadError(false);
      }
    }
    setInputData({ ...inputData, [e.target.name]: e.target.files });
  };

  const resetTarget = (e) => {
    e.target.value = null;
  };

  const toastMethod = (status, message) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        title: "Error",
        description: `${message}`,
        status: `${status}`,
        duration: 6000,
        isClosable: true,
      });
    }
    return false;
  };

  const customToast = () => {
    toast({
      id,
      title: "Error",
      render: ({ onClose }) => (
        <Flex bgColor="#e5433e" borderRadius="5px" color="#fff" padding={3}>
          <HStack alignItems="flex-start">
            <Flex direction="column">
              <Text>Password does not meet the requirements: </Text>
              <Text>1. Must have at least one uppercase character </Text>
              <Text>2. Must have at least one lowercase character</Text>
              <Text>3. Must have at least one digit </Text>
              <Text>4. Must have at least one special character</Text>
              <Text> 5. Must be of minimum length 8 and maximum 16.</Text>
            </Flex>
            <Button
              bgColor="#e5433e"
              onClick={onClose}
              _hover={{ bgColor: "red" }}
              _focus={{ outline: "none" }}
            >
              <AiOutlineClose />
            </Button>
          </HStack>
        </Flex>
      ),
    });
  };

  const validName = (name) => {
    const letters = /^[A-Za-z][A-Za-z ]+$/;
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        name
      );
    if (resp) {
      setFirstNameError(true);
      setFirstNameMessageError("First Name should not have emojis");
      return false;
    }
    if (name.length < 3 || name.length > 35) {
      setFirstNameError(true);
      setFirstNameMessageError("First name must be minimum 3 and maximum 35");
      return false;
    }
    if (name.match(letters)) {
      setFirstNameError(false);
      return true;
    } else {
      setFirstNameError(true);
      setFirstNameMessageError("First name must be alphabetical");
      return false;
    }
  };
  const validLastName = (name) => {
    const letters = /^[A-Za-z][A-Za-z ]+$/;
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        name
      );
    if (resp) {
      // toastMethod("error", "name should not have emojis");
      setLastNameError(true);
      setLastNameMessage("Last Name should not have emojis");
      return false;
    }
    if (name.length < 3 || name.length > 35) {
      // toastMethod(
      //   "error",
      //   "name should be of length between 3 and 35 character"
      // );
      setLastNameError(true);
      setLastNameMessage("Last name must be minimum 3 and maximum 35");
      return false;
    }
    if (name.match(letters)) {
      setError(false);
      return true;
    } else {
      // toastMethod("error", "name should only have alphabets");
      setLastNameError(true);
      setLastNameMessage("Last name must be alphabetical");
      return false;
    }
  };
  const validDesignation = (name) => {
    const letters = /^[A-Za-z][A-Za-z ]+$/;
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        name
      );
    if (resp) {
      // toastMethod("error", "name should not have emojis");
      setDesignationError(true);
      setDesignationMessage("Designation should not have emojis");
      return false;
    }
    if (name.length < 3 || name.length > 35) {
      // toastMethod(
      //   "error",
      //   "name should be of length between 3 and 35 character"
      // );
      setDesignationError(true);
      setDesignationMessage(
        "Designation must be minimum 3 and maximum 45 characters long"
      );
      return false;
    }
    if (name.match(letters)) {
      setDesignationError(false);
      return true;
    } else {
      // toastMethod("error", "name should only have alphabets");
      setDesignationError(true);
      setDesignationMessage("Designation must be alphabetical");
      return false;
    }
  };

  const validContact = (contact) => {
    const letters = /^\d+$/;
    if (contact.length < 10) {
      // toastMethod("error", "Length of contact number cannot be less than 10");
      setContactError(true);
      setContactMessage("Length of contact number cannot be less than 10");
      return false;
    }

    if (contact.match(letters)) {
      // toastMethod("error", "Contact should only be numbers");
      setContactError(false);
      return true;
    } else {
      setContactError(true);
      setContactMessage("Contact must be numeric");
      return false;
    }
  };

  const validEmail = (email) => {
    const pattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}[ ]*$/;
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        email
      );
    if (resp) {
      setEmailError(true);
      setEmailMessage("Email should not contain emojis");
      return false;
    }
    if (email.match(pattern)) {
      setEmailError(false);
      return true;
    } else {
      // toastMethod(
      //   "error",
      //   "Email should be of following format: email@domain.com"
      // );
      setEmailError(true);
      setEmailMessage("Email should be of following format: email@domain.com");
      return false;
    }
  };
  const validRegistration = (registration1) => {
    const pattern = /^[a-zA-Z0-9]+$/;
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        registration1
      );
    if (resp) {
      setRegistrationError(true);
      setRegistrationMessage("Registration should not contain emojis");
      return false;
    }
    if (registration1.match(pattern)) {
      setRegistrationError(false);
      return true;
    } else {
      // toastMethod(
      //   "error",
      //   "Email should be of following format: email@domain.com"
      // );
      setRegistrationError(true);
      setRegistrationMessage("Registration must be Alphanumeric");
      return false;
    }
  };

  const validAge = (dob) => {
    const today = new Date();
    const date = new Date(dob);
    const difference = (today - date) / (1000 * 60 * 60 * 24 * 365);
    if (difference < 18) {
      setDobError(true);
      setDobMessage("User must have at least 18 years");
      return false;
    } else if (dob === "") {
      setDobError(true);
      return false;
    } else {
      setDobError(false);
      return true;
    }
  };

  const validPassword = (password) => {
    const resp =
      /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
        password
      );

    const regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;
    if (resp) {
      setPasswordError(true);
      setPasswordMessage("Passwords should not contain emojis");
      // toastMethod("error", "Passwords should not contain emojis");
      return false;
    }

    if (!password.match(regularExpression)) {
      // console.log("error or not");
      setPasswordError(true);
      setPasswordMessage(
        " Password must have a capital charater, a lowercase character, a special symbol and a number"
      );
      return false;
    } else if (password.length < 8 || password.length > 16) {
      // customToast();
      setPasswordError(true);
      setPasswordMessage(
        "Password must be minimum 8 and maximum 16 character long"
      );
      return false;
    } else {
      setPasswordError(false);
      return true;
    }
  };
  const validGender = (gender) => {
    if (!(gender === "")) {
      setGenderError(false);
      return true;
    } else {
      // customToast();
      setGenderError(true);
      return false;
    }
  };
  const validExperience = (experience) => {
    if (!(experience === "")) {
      setExperienceError(false);
      return true;
    } else {
      // customToast();
      setExperienceError(true);
      return false;
    }
  };
  const validHighQualification = (qualification) => {
    if (!(qualification === "")) {
      setQualificationError(false);
      return true;
    } else {
      // customToast();
      setQualificationError(true);
      return false;
    }
  };
  const validImageUpload = (image) => {
    if (!(image === "")) {
      setImageUploadError(false);
      return true;
    } else {
      setImageUploadError(true);
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      !validName(inputData.firstName) ||
      !validLastName(inputData.lastName) ||
      !validDesignation(inputData.designation) ||
      !validContact(inputData.contact) ||
      !validEmail(inputData.emailAddress) ||
      !validRegistration(inputData.registration) ||
      !validAge(inputData.dob) ||
      !validPassword(inputData.password) ||
      !validGender(inputData.gender) ||
      !validHighQualification(inputData.qualification) ||
      !validExperience(inputData.exp) ||
      !validImageUpload(inputData.signature)
    ) {
      setLoading(false);
      if (validName(inputData.firstName) === false) {
        setFirstNameError(true);
        setFirstNameMessageError("This field is required");
      }
      if (validLastName(inputData.lastName) === false) {
        setLastNameError(true);
        setLastNameMessage("This field is required");
      }
      if (validDesignation(inputData.designation) === false) {
        setDesignationError(true);
        setDesignationMessage("This field is required");
      }
      if (validContact(inputData.contact) === false) {
        setContactError(true);
        setContactMessage("This field is required");
      }
      if (validEmail(inputData.emailAddress) === false) {
        setEmailError(true);
        setEmailMessage("This field is required");
      }
      if (validRegistration(inputData.registration) === false) {
        setRegistrationError(true);
        setRegistrationMessage("This field is required");
      }
      if (validAge(inputData.dob) === false) {
        setDobError(true);
        setDobMessage("This field is required");
      }
      if (validPassword(inputData.password) === false) {
        setPasswordError(true);
        setPasswordMessage("This field is required");
      }
      if (validGender(inputData.gender) === false) {
        setGenderError(true);
        setGenderMessage("This field is required");
      }
      if (validHighQualification(inputData.qualification) === false) {
        setQualificationError(true);
        setQualificationMessage("This field is required");
      }
      if (validExperience(inputData.exp) === false) {
        setExperienceError(true);
        setExperienceMessage("This field is required");
      }
      if (validImageUpload(inputData.signature) === false) {
        setImageUploadError(true);
        setImageUploadMessage("This field is required");
      }
      return;
    }

    const signatureForm = new FormData();
    signatureForm.append("file", inputData.signature[0]);

    const signatureUrls = await axios.post(
      `${Environment.USER_URL}/upload_signature`,
      signatureForm
    );

    try {
      await addUser({
        firstName: inputData.firstName.trimEnd(),
        lastName: inputData.lastName.trimEnd(),
        emailAddress: inputData.emailAddress.trimEnd().toLowerCase(),
        userType: state.userType.trimEnd(),
        phoneNumber: inputData.contact,
        dateOfBirth: inputData.dob,
        gender: inputData.gender,
        designation: inputData.designation.trimEnd(),
        yearOfExperience: inputData.exp,
        highestDegree: inputData.qualification,
        registrationNumber: inputData.registration.trimEnd(),
        address: inputData.address.trimEnd(),
        password: inputData.password.trimEnd(),
        signature: signatureUrls.data,
      }).unwrap();
      setInputData({
        firstName: "",
        lastName: "",
        emailAddress: "",
        contact: "",
        dob: "",
        gender: "",
        designation: "",
        exp: "",
        qualification: "",
        registration: "",
        address: "",
        password: generatePassword(),
        signature: "",
      });
      setLoading(false);
      toast({
        title: "Status",
        description: "User created successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      setLoading(false);
      if (err.data.message === "The user already exists.") {
        setEmailError(true);
        setEmailMessage("Email address is already exists.");
      }
      if (
        err.data.message ===
        "User validation failed: phoneNumber: Phone Number already exists"
      ) {
        setContactError(true);
        setContactMessage("Phone number is already exists");
      }
      toast({
        title: "Error",
        description: `Failed to add ${inputData.firstName}, try again!`,
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    return false;
  };
  return (
    <>
      {loading && (
        <Spinner
          pos="absolute"
          top="49%"
          left="50%"
          transform="translate(-50%,-50%)"
          size="xl"
          thickness="4px"
          speed="0.8s"
          zIndex="1"
        />
      )}
      <Flex
        w="100%"
        h={ifSmallScreen ? "calc(100vh - 44px)" : "calc(100vh - 4.8148vh)"}
        padding="8px 25px 0px 30px"
        direction="column"
        bgColor="light.1000"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        zIndex={0}
      >
        <Flex justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text fontWeight={600} fontSize={16}>
              Add{" "}
              {state.userType === "pathologist"
                ? "Pathologist"
                : "Technologist"}
            </Text>
            <Text fontSize={14}>
              Fill the details below for adding a{" "}
              {state.userType === "pathologist"
                ? "Pathologist"
                : "Technologist"}{" "}
              in your organisation.
            </Text>
          </VStack>
          <HStack gap="10px">
            <Button
              width="150px"
              fontSize={14}
              height="37px"
              padding="10px 16px 10px 16px"
              bgColor="light.1000"
              border="1px solid #00153F"
              borderRadius={0}
              color="light.400"
              _focus={{ outline: "none" }}
              onClick={() => navigate("/dashboard/admin")}
            >
              Cancel
            </Button>
            <Button
              width="150px"
              fontSize={14}
              height="37px"
              padding="10px 16px 10px 16px"
              bgColor="#3B5D7C"
              borderRadius={0}
              color="#fff"
              _focus={{ outline: "none" }}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </HStack>
        </Flex>
        <Flex w="100%" mt="25px" fontSize={14} justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text>First Name*</Text>
            <Input
              name="firstName"
              value={inputData.firstName}
              w="25vw"
              h="33px"
              fontSize="14px"
              borderRadius={0}
              css={firstNameError ? { border: "1px solid red" } : ""}
              _focus={
                firstNameError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                firstNameError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              bgColor="#fff"
              onChange={handleInput}
              maxLength={35}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {firstNameError ? firstNameMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Last Name*</Text>
            <Input
              name="lastName"
              fontSize="14px"
              value={inputData.lastName}
              w="25vw"
              h="33px"
              borderRadius={0}
              css={lastNameError ? { border: "1px solid red" } : ""}
              _focus={
                lastNameError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                lastNameError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              bgColor="#fff"
              onChange={handleInput}
              maxLength={35}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {lastNameError ? lastNameMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Email Address*</Text>
            <Input
              name="emailAddress"
              value={inputData.emailAddress}
              css={emailError ? { border: "1px solid red" } : ""}
              _focus={
                emailError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                emailError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              w="25vw"
              h="33px"
              fontSize="14px"
              borderRadius={0}
              bgColor="#fff"
              onChange={handleInput}
              type="email"
              maxLength={65}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px" noOfLines={2}>
              {emailError ? <Text>{emailMessageError}</Text> : " "}
            </Text>
          </VStack>
        </Flex>
        <Flex w="100%" mt="25px" fontSize={14} justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text>Gender*</Text>
            <Select
              w="25vw"
              h="33px"
              fontSize={14}
              css={genderError ? { border: "1px solid red" } : ""}
              _focus={
                genderError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                genderError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              borderRadius={0}
              name="gender"
              value={inputData.gender}
              onChange={handleInput}
              bgColor="#fff"
              color={inputData.gender ? "#000000" : "#AEAEAE"}
            >
              <option selected hidden fontSize={14}>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="nonbinary">Non-binary</option>
              {/* removing these for now */}
              {/* <option value="transgender">Transgender</option>
            <option value="intersex">Intersex</option> */}
            </Select>
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {genderError ? genderMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Designation*</Text>
            <Input
              name="designation"
              value={inputData.designation}
              w="25vw"
              h="33px"
              color={inputData.designation ? "#000000" : "#AEAEAE"}
              css={designationError ? { border: "1px solid red" } : ""}
              _focus={
                designationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                designationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              fontSize={14}
              bgColor="#fff"
              onChange={handleInput}
              maxLength={45}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {designationError ? designationMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Experience*</Text>
            <Select
              w="25vw"
              h="33px"
              fontSize={14}
              borderRadius={0}
              css={experienceError ? { border: "1px solid red" } : ""}
              _focus={
                experienceError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                experienceError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              name="exp"
              value={inputData.exp}
              color={inputData.exp ? "#000000" : "#AEAEAE"}
              onChange={handleInput}
              bgColor="#fff"
            >
              <option selected hidden fontSize={14}>
                Select Experience
              </option>
              <option value="lessthan1">Less than 1 year</option>
              <option value="1year">1 year</option>
              <option value="2year">2 year</option>
              <option value="3year">3 year</option>
              <option value="4year">4 year</option>
              <option value="above5year">above 5 year</option>
            </Select>
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {experienceError ? experienceMessageError : " "}
            </Text>
          </VStack>
        </Flex>
        <Flex w="100%" mt="25px" fontSize={14} justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text>Highest Qualification*</Text>
            <Select
              w="25vw"
              h="33px"
              fontSize={14}
              css={qualificationError ? { border: "1px solid red" } : ""}
              _focus={
                qualificationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                qualificationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              name="qualification"
              value={inputData.qualification}
              color={inputData.qualification ? "#000000" : "#AEAEAE"}
              onChange={handleInput}
              bgColor="#fff"
            >
              <option selected hidden fontSize={14}>
                Select qualification
              </option>

              <option value="mbbs">MBBS</option>
              <option value="md">MD</option>
              <option value="dm">DM</option>
              <option value="fellowship">Fellowship</option>
              <option value="other">Other</option>
            </Select>
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {qualificationError ? qualificationMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Registration Number*</Text>
            <Input
              name="registration"
              value={inputData.registration}
              w="25vw"
              h="33px"
              fontSize={14}
              css={registrationError ? { border: "1px solid red" } : ""}
              _focus={
                registrationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                registrationError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              bgColor="#fff"
              onChange={handleInput}
              maxLength={14}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {registrationError ? registrationMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start" w="25vw" h="91px">
            <Text>Address</Text>
            <Textarea
              fontSize={14}
              resize="none"
              name="address"
              value={inputData.address}
              _focus={{ outline: "none", border: "1px solid blue" }}
              bgColor="#fff"
              onChange={handleInput}
              maxLength={45}
            />
          </VStack>
        </Flex>
        <Flex w="100%" mt="25px" fontSize={14} justifyContent="space-between">
          <VStack alignItems="flex-start">
            <Text>Contact number*</Text>
            <Input
              type="tel"
              name="contact"
              value={inputData.contact}
              w="25vw"
              h="33px"
              css={contactError ? { border: "1px solid red" } : ""}
              _focus={
                contactError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                contactError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              fontSize={14}
              bgColor="#fff"
              onChange={handleInput}
              maxLength={10}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {contactError ? contactMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text>Date of Birth*</Text>
            <Input
              id={inputData.dob ? "dateblackid" : "dateid"}
              name="dob"
              value={inputData.dob}
              w="25vw"
              h="33px"
              fontSize={14}
              css={dobError ? { border: "1px solid red" } : ""}
              _focus={
                dobError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "1px solid blue" }
              }
              _hover={
                dobError
                  ? { outline: "none", border: "1px solid red" }
                  : { outline: "none", border: "" }
              }
              bgColor="#fff"
              type="date"
              min="1900-01-01"
              max="2010-01-01"
              onChange={handleInput}
            />
            <Text fontSize="11px" color="red" paddingLeft="17px">
              {dobError ? dobMessageError : " "}
            </Text>
          </VStack>
          <VStack alignItems="flex-start" w="25vw" h="33px">
            <Flex>Set Password*</Flex>

            <InputGroup css={passwordError ? { border: "1px solid red" } : ""}>
              <Input
                fontSize={14}
                w="25vw"
                h="36px"
                type={show ? "text" : "password"}
                name="password"
                value={inputData.password}
                _focus={
                  passwordError
                    ? { outline: "none" }
                    : { outline: "none", border: "1px solid blue" }
                }
                _hover={
                  passwordError
                    ? { outline: "none", border: "1px solid red" }
                    : { outline: "none", border: "" }
                }
                bgColor="#fff"
                onChange={handleInput}
                maxLength={16}
                onPaste={handleChange}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCut={handleChange}
              />
              <InputRightElement h="36px" w="35px" paddingRight="2px">
                <Button
                  size="sm"
                  _focus={{ outline: "none" }}
                  onClick={() => setShow(!show)}
                >
                  {show ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <Text fontSize="11px" color="red" paddingLeft="17px">
              {passwordError ? passwordMessageError : " "}
            </Text>
          </VStack>
        </Flex>
        <Flex w="100%" mt="15px" fontSize={14} justifyContent="space-between">
          <HStack>
            <VStack alignItems="flex-start">
              <Text>Upload signature*</Text>
              <Flex bg="#3B4149" position="relative" w="140px" h="44px">
                <Image src={uploadSignature} px="10px" />
                <Text py="11px" color="light.100" fontSize="14px">
                  Select Image
                </Text>
                <Input
                  id="signature"
                  name="signature"
                  position="absolute"
                  opacity="0"
                  onChange={onSelectFile}
                  onClick={resetTarget}
                  top="0"
                  css={imageUploadError ? { border: "1px solid red" } : ""}
                  _focus={
                    imageUploadError
                      ? { outline: "none", border: "1px solid red" }
                      : { outline: "none", border: "1px solid blue" }
                  }
                  left="0"
                  borderColor="#000"
                  // resize="none"
                  type="file"
                  accept="image/*"
                />
              </Flex>
              <Text
                fontSize="11px"
                color="red"
                paddingLeft="11px"
                paddingBottom="5px"
              >
                {imageUploadError ? imageUploadMessageError : " "}
              </Text>
            </VStack>
            {inputData.signature.length === 0 ? null : (
              <VStack spacing={0} pt="25px" alignItems="flex-start">
                <Text>Signature uploaded!</Text>
                <Text paddingBottom="19.3px">
                  {inputData.signature.length === 0
                    ? null
                    : inputData.signature[0].name}
                </Text>
              </VStack>
            )}
          </HStack>
        </Flex>
      </Flex>
    </>
  );
};

export default MemberDetails;
