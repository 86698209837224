/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import {
  Text,
  Image,
  HStack,
  Avatar,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  useMediaQuery,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { BiChevronDown } from "react-icons/bi";
import { MdOutlineNotifications } from "react-icons/md";
import { HiOutlinePlusCircle } from "react-icons/hi";
import MedAIIcon from "../Static/Images/medAIicon.svg";
import Queries from "../Components/NewDashboard/Queries";
import { useLazyUniversalSearchQuery } from "../state/API/HospitalApi";

const CustomTooltip = ({ children, label, userName }) => {
  return (
    <Tooltip
      label={userName.length > 16 ? label : null}
      aria-label="A tooltip"
      zIndex={100}
    >
      {children}
    </Tooltip>
  );
};

const Header = ({
  userInfo,
  searchResponse,
  setSearchResponse,
  department,
  setSearchInput,
  searchInput,
  pageNumber,
  setPageNumber,
}) => {
  const [currentDepartment, setCurrentDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [shared, setShared] = useState("");
  const [archive, setArchive] = useState("");
  const [caseStatus, setCaseStatus] = useState("");

  const [searchQuery, { data }] = useLazyUniversalSearchQuery();
  const navigate = useNavigate();
  const [activeScreen, setActiveScreen] = useState("");
  useEffect(() => {
    setCurrentDepartment("");
    setCategory("");
    setShared("");
    setArchive("");
    setArchive("");
    setCaseStatus("");
    if (
      department === "histopathology" ||
      department === "hematopathology" ||
      department === "cytopathology"
    ) {
      setCurrentDepartment(department);
    } else if (department === "reportedCases") {
      setCategory(true);
    } else if (department === "pendingCases") {
      setCategory(false);
    } else if (department === "sharedCases") {
      setShared(true);
    } else if (department === "archive") {
      setArchive(true);
    } else if (department === "urgentCases") {
      setCaseStatus(true);
    }
  }, [department]);

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setSearchResponse([...new Set([...searchResponse, ...data?.data])]);
    }
  }, [data]);

  const searchFunction = (e) => {
    setSearchResponse([]);
    setPageNumber(1);
    setSearchInput(e.target.value.toLowerCase());
    setTimeout(() => {
      if (e?.target.value?.length > 0) {
        searchQuery({
          limit: 11,
          pageNumber: 1,
          payload: e.target.value.toLowerCase(),
          category,
          department: currentDepartment,
          archive,
          shared,
          caseStatus,
        });
      }
    }, 400);
  };
  useEffect(() => {
    if (searchInput?.length > 0) {
      searchQuery({
        limit: 11,
        pageNumber,
        payload: searchInput,
        category,
        department: currentDepartment,
        archive,
        shared,
        caseStatus,
      });
    }
  }, [pageNumber]);

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { logout } = useAuth0();
  const location = useLocation();

  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  return (
    <Flex
      h={ifWidthLessthan1920 ? "44px" : "6.11vh"}
      minH="46px"
      w="100%"
      background="light.100"
      justifyContent="space-between"
      fontSize={ifWidthLessthan1920 ? "14px" : " 0.72916vw"}
      alignItems="center"
      color="#000"
    >
      <Flex alignItems="center">
        <Link
          to={
            userInfo?.userType
              ? `/dashboard/${userInfo?.userType}`
              : "/dashboard"
          }
          _hover={{
            textDecoration: "none",
          }}
          _focus={{ outline: "none" }}
        >
          <HStack
            px={ifWidthLessthan1920 ? "16px" : "0.8333vw"}
            py={ifWidthLessthan1920 ? "15px" : "2.17vh"}
            h="100%"
          >
            <Link href={`/dashboard/${userInfo?.userType}`}>
              <Image src={MedAIIcon} h="58px" w="58px" />
            </Link>
          </HStack>
        </Link>
        <HStack h="100%">
          {userInfo?.userType !== "admin" && (
            <Button
              onClick={() => {
                handleLinkClick(`/dashboard/${userInfo?.userType}`);
                navigate(`/dashboard/${userInfo?.userType}`);
              }}
              fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
              fontWeight={
                activeLink === `/dashboard/${userInfo?.userType}`
                  ? "600"
                  : "500"
              }
              bgColor="inherit"
              _hover={{ bgColor: "inherit" }}
              color={
                activeLink === `/dashboard/${userInfo?.userType}`
                  ? "#1B75BC"
                  : "#000"
              }
              borderBottom={
                activeLink === `/dashboard/${userInfo?.userType}`
                  ? "2px solid #1B75BC"
                  : ""
              }
              borderRadius={0}
            >
              Cases
            </Button>
          )}
          {userInfo?.userType !== "admin" && (
            <Button
              fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
              fontWeight={activeLink === "chats" ? "600" : "500"}
              bgColor="inherit"
              onClick={() => {
                handleLinkClick("/chats");
                navigate("/chats");
              }}
              _hover={{ bgColor: "inherit" }}
              color={activeLink === "/chats" ? "#1B75BC" : "#000"}
              borderBottom={activeLink === "/chats" ? "2px solid #1B75BC" : ""}
              borderRadius={0}
            >
              Chats
            </Button>
          )}
        </HStack>
      </Flex>
      <Flex alignItems="center">
        <HStack justifyContent="space-between" maxW="900px">
          {(userInfo?.userType !== "admin" &&
            location.pathname === "/dashboard/pathologist") ||
          location.pathname === "/dashboard/technologist" ? (
            <>
              <InputGroup
                borderRadius={2}
                background="#fff"
                color="#000"
                w="24vw"
              >
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon />}
                  opacity={0.3}
                />
                <Input
                  placeholder="Search"
                  maxLength={18}
                  onChange={(e) => searchFunction(e)}
                  _focus={{ outline: "none" }}
                  value={searchInput}
                />
              </InputGroup>
              <Button
                bgColor="light.100"
                border="1px solid #E8E8E8"
                borderRadius="5px"
                onClick={() => {
                  window.location.replace("/create-case");
                }}
              >
                <HiOutlinePlusCircle />
                <Text
                  fontSize={ifWidthLessthan1920 ? "14px" : "0.729vw"}
                  fontWeight="500"
                  ml="5px"
                >
                  New case
                </Text>
              </Button>
            </>
          ) : null}

          {/* commenting out viewer searchbar for now and whoever works on it please don't remove this commented code */}

          {(userInfo?.userType !== "admin" &&
            location.pathname === "/dashboard/technologist") ||
          location.pathname === "/dashboard/pathologist" ? (
            <Popover>
              <PopoverTrigger>
                <IconButton
                  borderRadius="5px"
                  border="1px solid #E8E8E8"
                  bgColor="inherit"
                  icon={<MdOutlineNotifications />}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Queries userInfo={userInfo} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : null}
          <CustomTooltip
            label={`${userInfo?.firstName} ${userInfo?.lastName}`}
            userName={`${userInfo?.firstName} ${userInfo?.lastName}`}
          >
            <HStack h="100%" spacing={0}>
              <Avatar
                size={ifWidthLessthan1920 ? "sm" : "md"}
                name={`${userInfo?.firstName} ${userInfo?.lastName}`}
              />
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiChevronDown size={20} />}
                  bgColor="inherit"
                  maxW="245px"
                  _focus={{ outline: "none" }}
                  _active={{ bgColor: "light.100" }}
                  _hover={{ bgColor: "light.100" }}
                >
                  <VStack spacing={0} alignItems="flex-start" maxW="100%">
                    <Text
                      fontSize={ifWidthLessthan1920 ? "13px" : "16px"}
                      fontWeight="600"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      wordBreak="break-word"
                      maxW="150px"
                    >
                      {userInfo?.userType === "pathologist"
                        ? `Dr. ${
                            userInfo?.firstName !== undefined
                              ? userInfo.firstName.charAt(0).toUpperCase() +
                                userInfo.firstName.slice(1)
                              : ""
                          } ${
                            userInfo?.lastName !== undefined
                              ? userInfo.lastName.charAt(0).toUpperCase() +
                                userInfo.lastName.slice(1)
                              : ""
                          }`
                        : `${
                            userInfo?.firstName !== undefined
                              ? userInfo.firstName.charAt(0).toUpperCase() +
                                userInfo.firstName.slice(1)
                              : ""
                          } ${
                            userInfo?.lastName !== undefined
                              ? userInfo.lastName.charAt(0).toUpperCase() +
                                userInfo.lastName.slice(1)
                              : ""
                          }`}
                    </Text>
                    <Text
                      fontSize={ifWidthLessthan1920 ? "11px" : "0.6vw"}
                      opacity="0.7"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxW="150px"
                    >
                      {userInfo?.designation}
                    </Text>
                  </VStack>
                </MenuButton>

                <MenuList p={0} zIndex="10000">
                  <MenuItem
                    onClick={() =>
                      logout({
                        returnTo: `${window.location.origin}/login`,
                      })
                    }
                  >
                    Log out
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CustomTooltip>
        </HStack>
      </Flex>
    </Flex>
  );
};
export default Header;
