import React, { useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "./Sidebar";
import MembersList from "./MembersList";
import DashboardStatistics from "./DashboardStatistics";
import {
  useGetUserInfoQuery,
  useOrganizationUserDetailsQuery,
} from "../state/API/HospitalApi";
import Loading from "../Components/Loading/loading";
import Header from "./Header";

const AdminDashboard = () => {
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const { data: orgDetails } = useOrganizationUserDetailsQuery({
    organizationId: userInfo?.organization._id,
  });
  const [searchUser, setSearchUser] = useState("");

  // console.log(userInfo);
  return isLoading ? (
    <Loading />
  ) : (
    <Flex w="100%" direction="column">
      {/* <Sidebar home documents={false} /> */}
      <Header userInfo={userInfo} />
      <Flex w="100%" bg="light.200">
        <MembersList
          orgDetails={orgDetails}
          setSearchUser={setSearchUser}
          searchUser={searchUser}
          flex="2 1 auto"
        />
        <Box mt="20px" h="92%" w="0.5px" bg="#DEDEDE" />
        <DashboardStatistics orgDetails={orgDetails} flex="1" />
      </Flex>
    </Flex>
  );
};

export default AdminDashboard;
