import React, { useEffect, useState } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Link, Flex } from "@chakra-ui/react";
import { useLazyGetSynopticReportQuery } from "../../state/API/HospitalApi";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "1.5vh 0vw",
  },
  tableBox: {
    minWidth: "400px",
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    padding: "0.5vh 2.5vw",
  },
  tableColumn: {
    minWidth: "33%",
    maxWidth: "33%",
    fontSize: "12px",
    height: "35px",
  },
  tableData: {
    display: "flex",
    flexDirection: "column",
  },
  tableDataLabel: {
    paddingLeft: "0.5208vw",
    paddingRight: "10px",
    marginTop: "5px",
  },
  tableDataLabelInfo: {
    padding: "0.7vh 0.5208vw",
    color: "#8F8F8F",
  },
  reportBox: {
    marginTop: "1.7vh",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0vh 2.5vw",
  },
  reportHeadline: {
    fontWeight: 600,
    fontSize: "12px",
  },
  title: {
    fontWeight: 600,
    fontSize: "12px",
    paddingBottom: "1vh",
    paddingTop: "1.5vh",
    paddingLeft: "1vw",
    width: "100%",
    backgroundColor: "#F7FBFD",
    margin: "0.6vh 0vw",
  },
  reportColumn: {
    minWidth: "45%",
    paddingTop: "12px",
    display: "flex",
    flexDirection: "column",
  },
  reportData: {
    fontWeight: "200",
    fontSize: "11px",
    paddingTop: "4px",
    color: "#8F8F8F",
    minHeight: "15px",
  },
  media: {
    width: "100%",
    paddingTop: "2vh",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  image: {
    width: "50px",
    height: "50px",
  },
  reporterBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "0vh 2.5vw",
  },
  reporterName: {
    color: "#3B5D7C",
    fontSize: "12px",
  },
  reporterDetails: {
    fontSize: "12px",
    paddingTop: "6px",
  },
  header: {
    height: "6vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "1vh 0vw",
    marginTop: "-1.5vh",
    backgroundColor: "#F7FBFD",
    borderBottom: "1px solid #8F8F8F",
    width: "100%",
    fontSize: "14px",
    marginLeft: "2vw",
  },
  subHeading: {
    width: "100%",
    fontSize: "12px",
    marginTop: "10px",
    paddingTop: "0.7vh",
    fontWeight: "bold",
    marginBottom: "-5px",
  },
});

const Report = ({ caseInfo, userInfo, response }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Prostate Cancer Synoptic Report</Text>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>PATIENT NAME</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.patientName?.firstName}`}
                {`${caseInfo?.patient?.patientName?.lastName}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>MRN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.uhid}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>AGE</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.age?.years
                    ? caseInfo?.patient?.age?.years
                    : ""
                }`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>GENDER</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.gender ? caseInfo?.patient?.gender : ""
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>CLINICIAN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.treatingDoctor}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.reportBox}>
          <Text style={styles.title}>CLINICAL</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              PREV.HISTORY OF PROSTATE CANCER
            </Text>
            <Text style={styles.reportData}>{response?.isPreviousHistory}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PREVIOUS BIOPSY</Text>
            <Text style={styles.reportData}>{response?.previousBiopsy}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PREVIOUS THERAPY</Text>
            <Text style={styles.reportData}>{response?.previousTherapy}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>CLINICAL SYMPTOMS</Text>
            <Text style={styles.reportData}>{response?.clinicalSymptoms}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PRE-BIOPSY SERUM PSA</Text>
            <Text style={styles.reportData}>{response?.preBiopsySerumPSA}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>CLINICAL STAGE</Text>
            <Text style={styles.reportData}>{response?.clinicalStage}</Text>
          </View>
          <Text style={styles.title}>MACROSCOPIC</Text>
          <Text style={styles.subHeading}>1.LEFT BASE</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.leftBaseCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.leftBaseLength}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>HISTOLOGICAL TUMOUR TYPE</Text>
            <Text style={styles.reportData}>
              {response?.leftBaseHistologicalTumourType}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>COEXISTENT PATHOLOGY</Text>
            <Text style={styles.reportData}>
              {response?.coExistentPathology}
            </Text>
          </View>
          <Text style={styles.subHeading}>2.LEFT MID</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.leftMidCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.leftMidLength}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>HISTOLOGICAL TUMOUR TYPE</Text>
            <Text style={styles.reportData}>
              {response?.leftMidHistologicalTumourType}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>GLEASON SCORE</Text>
            <Text style={styles.reportData}>
              {response?.leftMidGleasonScore}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ISUP GRADE</Text>
            <Text style={styles.reportData}>{response?.isUpGrade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>GLEASON PATTERN</Text>
            <Text style={styles.reportData}>{response?.gleasonPattern}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PERINEURAL INVASION</Text>
            <Text style={styles.reportData}>
              {response?.leftMidPerineuralInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SEMINAL VESICLE INVASUION</Text>
            <Text style={styles.reportData}>
              {response?.leftMidSeminalInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LYMPHOVASCULAR INVASION</Text>
            <Text style={styles.reportData}>
              {response?.leftMidLymphovascularInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>EXTRAPROSTATIC EXTENSIONM</Text>
            <Text style={styles.reportData}>
              {response?.leftMidExtraprostateExtension}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              INTRADUCTUAL CA.OF PROSTATE
            </Text>
            <Text style={styles.reportData}>
              {response?.leftMidIntraductualProstate}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>COEXISTENT EXTENSION</Text>
            <Text style={styles.reportData}>
              {response?.leftMidCoexistentExtension}
            </Text>
          </View>
          <Text style={styles.subHeading}>3.LEFT APEX</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.leftApexCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.leftApexLength}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PERINEURAL INVASION</Text>
            <Text style={styles.reportData}>
              {response?.leftApexPerineuralInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SEMINAL VESICLE INVASUION</Text>
            <Text style={styles.reportData}>
              {response?.leftApexSeminalInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LYMPHOVASCULAR INVASION</Text>
            <Text style={styles.reportData}>
              {response?.leftApexLymphovascularInvasion}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>EXTRAPROSTATIC EXTENSIONM</Text>
            <Text style={styles.reportData}>
              {response?.leftApexExtraprostateExtension}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              INTRADUCTUAL CA.OF PROSTATE
            </Text>
            <Text style={styles.reportData}>
              {response?.leftApexIntraductualProstate}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>COEXISTENT EXTENSION</Text>
            <Text style={styles.reportData}>
              {response?.leftApexCoexistentExtension}
            </Text>
          </View>
          <Text style={styles.subHeading}>4.RIGHT BASE</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.rightBaseCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.rightBaseLength}</Text>
          </View>
          <Text style={styles.subHeading}>5.RIGHT MID</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.rightMidCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.rightMidLength}</Text>
          </View>
          <Text style={styles.subHeading}>6.RIGHT APEX</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NUMBER OF CORES</Text>
            <Text style={styles.reportData}>{response?.rightApexCores}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LENGHTS(S)</Text>
            <Text style={styles.reportData}>{response?.rightApexLength}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>COMMENT</Text>
            <Text style={styles.reportData}>{response?.comments}</Text>
          </View>
        </View>
        <View style={styles.reporterBox}>
          <Text
            style={styles.reporterName}
          >{`Dr. ${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
          <Text style={styles.reporterDetails}>
            {userInfo?.highestDegree?.split("")?.join(".")?.toUpperCase()}
          </Text>
          <Text style={styles.reporterDetails}>{`${
            userInfo?.organization?.name
          }, ${
            userInfo?.organization?.address
              ? userInfo?.organization?.address
              : ""
          }`}</Text>
        </View>
      </Page>
    </Document>
  );
};

const ProstateCancer = ({
  caseInfo,
  slideInfo,
  userInfo,
  fetchSynopticReport,
  setReportLinkData,
}) => {
  const [fetchReportData] = useLazyGetSynopticReportQuery();
  const [response, setResponse] = useState("");
  useEffect(() => {
    async function fetchReport() {
      if (caseInfo?._id) {
        const resp = await fetchReportData({
          reportType: "prostate-cancer",
          caseId: caseInfo?._id,
        });
        setResponse(resp?.data);
      }
    }
    fetchReport();
  }, [fetchSynopticReport]);

  return (
    <Flex direction="column" w="100%" h="100%" alignItems="center">
      <Flex
        overflowY="scroll"
        overFlowX="clip"
        h="90%"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        bgColor="#fff"
      >
        <Report
          caseInfo={caseInfo}
          slideInfo={slideInfo}
          userInfo={userInfo}
          response={response?.data}
        />
      </Flex>
      <PDFDownloadLink
        document={
          <Report
            caseInfo={caseInfo}
            slideInfo={slideInfo}
            userInfo={userInfo}
            response={response?.data}
          />
        }
        fileName="case_report_breast_cancer.pdf"
      >
        {/* {({ url }) =>
          // setReportLinkData(url);
          // <Link href={url} isExternal style={{ color: "#1B75BC" }}>
          //   Download prostate cancer report
          // </Link>
        } */}
        {({ url }) => setReportLinkData(url)}
      </PDFDownloadLink>
    </Flex>
  );
};

export default ProstateCancer;
