import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";

const ChatHeader = () => {
  return (
    <Flex
      w="100%"
      h="61px"
      justify="space-between"
      fontSize="20px"
      fontWeight="500"
      align="center"
      px="23px"
      bg="white"
    >
      <Text color="light.1006">Chats</Text>
    </Flex>
  );
};

export default ChatHeader;
