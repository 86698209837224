import { Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Boxes = ({ heading, number }) => {
  return (
    <Flex
      minW="168px"
      h="88px"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      alignItems="center"
      justifyContent="center"
      fontSize="14px"
      mr="20px"
      mb="10px"
      bg="white"
    >
      <VStack alignItems="flex-start">
        <Text>{heading}</Text>
        <Text fontSize="16px" fontWeight="600">
          {number}
        </Text>
      </VStack>
    </Flex>
  );
};

const DashboardStatistics = ({ orgDetails, ...restProps }) => {
  return (
    <Flex
      padding="15px"
      w="100%"
      paddingTop="25px"
      flexWrap="wrap"
      height="fit-content"
      {...restProps}
    >
      <Boxes heading="Total Pathologists" number={orgDetails?.pathologistNo} />
      <Boxes
        heading="Total Technologists"
        number={orgDetails?.technologistNo}
      />
    </Flex>
  );
};

export default DashboardStatistics;
