import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  caseDetails: {
    caseName: "",
    departmentTo: "",
    departmentFrom: "",
    accessionId: "",
    caseId: `CID/${moment(new Date()).format("DD/MM/YY")}/${(Math.random() + 1)
      .toString(36)
      .substring(2, 7)}`,
    organ: "",
    specimenSize: "",
    hospital: "",
    clinician: "",
    helplineNumber: "",
    patientName: "",
    patientGender: "",
    patientDOB: "",
    patientContactNumber: "",
    patientAddress: "",
    countryCode: "",
    patientDescription: "",
    grossRecievedAt: "",
    grossDescription: "",
    grossingInstructions: "",
    grossImages: [],
    clinicalHistory: [],
    radiologyImages: [],
    clinicalHistoryDetails: "",
    radiologyInstructions: "",
    urgentCase: "normal",
    uhid: "",
    turnAroundTime: "",
  },
  slides: [],
  members: [],
  membersInfo: [],
  uploadedFile: "",
};
const newCaseSlice = createSlice({
  name: "newCase",
  initialState,
  reducers: {
    updateCase: (state, action) => {
      state.caseDetails[action.payload.name] = action.payload.value;
    },
    // updateSlides: (state, action) => {
    //   state.caseDetails["slides"] = action.payload;
    // },
    addGrossImages: (state, action) => {
      state.caseDetails.grossImages = action.payload;
    },
    addClinicalHistory: (state, action) => {
      state.caseDetails.clinicalHistory = action.payload;
    },
    addRadiologyImages: (state, action) => {
      state.caseDetails.radiologyImages = action.payload;
    },
    addMembers: (state, action) => {
      state.members.push({
        // ...action.payload.info,
        id: action.payload.info._id,
      });
      state.membersInfo.push({
        ...action.payload.info,
        email: action.payload.email,
      });
    },
    updateFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    addSlides: (state, action) => {
      state.slides = action.payload;
    },
    // resetCases: (state) => {
    //   state.slides = [];
    //   state.caseDetails["grossImages"] = [];
    //   state.caseDetails["clinicalHistory"] = [];
    //   state.uploadedFile = "";
    // },
    resetNewCase: (state) => {
      state.caseDetails = initialState.caseDetails;
      state.members = initialState.members;
      state.membersInfo = initialState.membersInfo;
      state.slides = [];
      state.uploadedFile = "";
    },
  },
});

export const {
  updateCase,
  resetNewCase,
  updateSlides,
  addMembers,
  updateFile,
  addMembersInfo,
  addSlides,
  resetCases,
  addGrossImages,
  addClinicalHistory,
  addRadiologyImages,
} = newCaseSlice.actions;

export default newCaseSlice.reducer;
