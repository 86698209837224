import React, { useState } from "react";
import {
  Box,
  Input,
  Text,
  HStack,
  Textarea,
  useMediaQuery,
  Image,
  Table,
  Thead,
  Tr,
  Tbody,
  Td,
  Flex,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { FiUpload } from "react-icons/fi";
import { updateCase, addGrossImages } from "../state/Reducers/newCaseReducer";
import imageIcon from "../Static/Images/imageIcon.svg";

const GrossDetails = ({ getFile2, fileDataGross, setFileDataGross }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const { caseDetails } = useSelector((state) => state.newCaseState);
  const dispatch = useDispatch();
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const handleCaseDetails = (e) => {
    dispatch(updateCase({ name: e.target.name, value: e.target.value }));
  };
  const onSelectFile = async (e) => {
    const { files } = e.target;
    const filesArray = Array.from(files);
    if (filesArray.length > 0) {
      setFileDataGross(true);
    }
    getFile2(filesArray);
    const imagesArray = filesArray.map((file) => file);
    dispatch(addGrossImages(imagesArray));
  };
  // const deleteImage = (e) => {
  //   const updatedImages = caseDetails?.grossImages.filter(
  //     (item) => item.name !== e.target.value
  //   );
  //   dispatch(addGrossImages(updatedImages));
  // };
  return (
    <Box
      fontFamily="inter"
      fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}
      mt="3.148vh"
    >
      <Flex mb="2vh" alignItems="center" w="100%">
        <Text fontSize={ifSmallScreen ? "16px" : "20px"}>Gross Details</Text>
      </Flex>

      {/* <Flex
        border="1px dashed #1B75BC"
        borderRadius="5px"
        bgColor="light.100"
        position="relative"
        h="140px"
        alignItems="center"
        justifyContent="center"
      >
        {caseDetails?.grossImages.length > 0 ? (
          caseDetails?.grossImages.map((image, index) => {
            return (
              <Box key={image.name} position="relative">
                {image.type === "image/jpeg" ||
                image.type === "image/jpg" ||
                image.type === "image/png" ? (
                  <Image
                    src={URL.createObjectURL(image)}
                    alt={image.name}
                    minW="10vw"
                    maxW="10vw"
                  />
                ) : (
                  <Flex>{`${index + 1}.${image.name}`}</Flex>
                )}
              </Box>
            );
          })
        ) : (
          <Flex direction="column" alignItems="center">
            <FiUpload size={30} color="#3B5D7C" />
            <Text>Drag and drop your images or browse to upload</Text>
          </Flex>
        )}

        <Input
          id="gross_images"
          name="grossImages"
          position="absolute"
          opacity="0"
          onInput={(e) => onSelectFile(e)}
          height="100%"
          top="0"
          left="0"
          bg="light.100"
          borderRadius={0}
          borderColor="#000"
          resize="none"
          type="file"
          accept=".pdf, image/*"
          multiple
        />
      </Flex> */}
      <Flex gap={8}>
        <Flex
          border="1px dashed #1B75BC"
          borderRadius="5px"
          bgColor="light.100"
          position="relative"
          h="140px"
          w={fileDataGross ? "50%" : "100%"}
          alignItems="center"
          justifyContent="center"
        >
          <Flex direction="column" alignItems="center">
            <FiUpload size={30} color="#3B5D7C" />
            <Text>Drag and drop your images or browse to upload</Text>
          </Flex>

          <Input
            id="gross_images"
            name="grossImages"
            position="absolute"
            opacity="0"
            onInput={(e) => onSelectFile(e)}
            height="100%"
            top="0"
            left="0"
            bg="light.100"
            borderRadius={0}
            borderColor="#000"
            resize="none"
            type="file"
            accept="image/*"
            multiple
          />
        </Flex>
        {fileDataGross ? (
          <Flex
            border="1px dashed #1B75BC"
            borderRadius="5px"
            bgColor="light.100"
            position="relative"
            h="140px"
            w="50%"
            direction="column"
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C4C4C4",
              },
            }}
          >
            {caseDetails?.grossImages.length > 0 ? (
              caseDetails?.grossImages.map((image, index) => {
                return (
                  <Flex
                    key={Math.random()}
                    borderRadius="5px"
                    h="35%"
                    m="8px"
                    bgColor="rgba(27, 117, 188, 0.10)"
                  >
                    <Image src={imageIcon} p="6px" />
                    <Text
                      fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
                      pt="4px"
                      pl="2px"
                    >
                      {`${image.name}`}
                    </Text>
                  </Flex>
                );
              })
            ) : (
              <Flex alignItems="center" justifyContent="center" height="100%">
                No Image Selected
              </Flex>
            )}
          </Flex>
        ) : null}
      </Flex>

      <Flex direction="column" mt="2vh">
        <Text mb="0.5vh" fontSize={ifSmallScreen ? "12px" : "14px"}>
          Additional Details
        </Text>
        <Textarea
          h="100%"
          maxH="18vh"
          minH="18vh"
          id="gross_description"
          name="grossDescription"
          bg="light.100"
          borderRadius={5}
          resize="none"
          placeholder="Enter text here"
          value={caseDetails?.grossDescription}
          onChange={(e) => handleCaseDetails(e)}
          maxLength="450"
        />
      </Flex>
    </Box>
  );
};

export default GrossDetails;
