import React from "react";
import {
  Flex,
  Text,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { AiOutlineUser, AiOutlineStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { RiGroupLine } from "react-icons/ri";

const ChatNavbar = ({ index, setIndex }) => {
  const tabList = [
    {
      icon: <AiOutlineUser />,
      key: 0,
    },
    {
      icon: <RiGroupLine />,
      key: 1,
    },
  ];
  return (
    <Flex w="100%" bg="white">
      <Tabs w="100%" index={index} onChange={(i) => setIndex(i)} isLazy>
        <TabList height="60px" w="100%" justifyContent="space-evenly" px="18px">
          {tabList.map((data) => (
            <Tab
              key={data.key}
              fontWeight="500"
              fontSize="22px"
              color="#dedede"
              _selected={{
                color: "light.1006",
                borderBottom: "2px solid #3B5D7C",
              }}
            >
              {data.icon}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Flex>
  );
};

export default ChatNavbar;
