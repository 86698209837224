/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, HStack, Text, VStack, Avatar, Input } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
  useGetFilterDataQuery,
  useGetUserInfoQuery,
  useGetUsersListInOrgQuery,
  useSearchUsersByNameQuery,
  useSearchCaseByIDQuery,
} from "../../state/API/HospitalApi";
import ScrollBar from "../../Components/other/Scrollbar";

const ChatLists = ({ setGroupData, setActiveGroup, tab }) => {
  const { user } = useAuth0();
  const { data: userInfo } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  const [searchName, setSearchName] = useState("");
  const [searchCase, setSearchCase] = useState("");

  const { data: usersList } = useGetUsersListInOrgQuery({
    organizationId: userInfo?.organization?._id,
  });

  const { data: userByName } = useSearchUsersByNameQuery({
    searchTerm: searchName,
    organizationId: userInfo?.organization?._id,
  });
  const { data: casesById } = useSearchCaseByIDQuery({
    searchTerm: searchCase,
    organizationId: userInfo?.organization?._id,
  });

  const [chatGroups, setChatGroups] = useState();
  const sharedCases = userInfo?.caseShared?.map((cases) => cases?.caseId);

  return (
    <ScrollBar>
      <Flex flexDirection="column">
        {tab === 0 ? (
          <Input
            onChange={(e) => setSearchName(e.target.value)}
            placeholder="Search user"
          />
        ) : (
          <Input
            onChange={(e) => setSearchCase(e.target.value)}
            placeholder="Search case"
          />
        )}

        {tab === 1
          ? casesById?.map((cases) => {
              return (
                <Flex
                  h="7.5vh"
                  minH="58px"
                  border="none"
                  w="100%"
                  bg="white"
                  _hover={{
                    bgColor: "#F6F6F6",
                    cursor: "pointer",
                    transition: "0.2s",
                  }}
                  onClick={() => {
                    setGroupData(cases);
                    setActiveGroup(cases?._id);
                  }}
                  letterSpacing="0.7px"
                  fontSize="caseInfo"
                  borderBottom="1px solid #DEDEDE"
                  key={uuidv4()}
                >
                  <HStack marginStart="0.8vw" border="none" w="100%">
                    <Avatar
                      name={cases?.caseId.slice(
                        cases?.caseId.length - 5,
                        cases?.caseId.length
                      )}
                      getInitials={(name) => name.slice(0, 2)}
                    />
                    <VStack alignItems="flex-start" spacing={3} w="100%">
                      <Flex
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        pr="1"
                        mt="-0rem !important"
                      >
                        <Text w="90%" fontSize="caseHeader" isTruncated>
                          {cases?.caseId}
                        </Text>
                      </Flex>
                    </VStack>
                  </HStack>
                </Flex>
              );
            })
          : userByName?.map((User) => {
              return (
                <Flex
                  h="7.5vh"
                  minH="58px"
                  border="none"
                  w="100%"
                  bg="white"
                  _hover={{
                    bgColor: "#F6F6F6",
                    cursor: "pointer",
                    transition: "0.2s",
                  }}
                  onClick={() => {
                    setGroupData(User);
                    setActiveGroup(User?._id);
                  }}
                  letterSpacing="0.7px"
                  fontSize="caseInfo"
                  borderBottom="1px solid #DEDEDE"
                  key={uuidv4()}
                >
                  <HStack marginStart="0.8vw" border="none" w="100%">
                    <Avatar
                      name={`${User?.firstName} ${User.lastName}`}
                      getInitials={(name) => name.slice(0, 2)}
                    />
                    <VStack alignItems="flex-start" spacing={3} w="100%">
                      <Flex
                        w="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        pt="0"
                        pr="1"
                        mt="-0rem !important"
                      >
                        <Text w="90%" fontSize="caseHeader" isTruncated>
                          {`${User?.firstName} ${User.lastName}`}
                        </Text>
                      </Flex>
                    </VStack>
                  </HStack>
                </Flex>
              );
            })}
      </Flex>
    </ScrollBar>
  );
};

export default ChatLists;
