/* eslint-disable consistent-return */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  HStack,
  Input,
  Text,
  VStack,
  Image,
  Checkbox,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useCreateUserAndOrgMutation } from "../../state/API/HospitalApi";
import sideImage from "../../Static/Images/wsiImage.png";

const UserDetails = () => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1919px)");
  const [inputData, setInputData] = useState({
    orgName: "",
    address: "",
    regNumber: "",
    zipcode: "",
    state: "",
    userType: "admin",
  });
  const { user, logout } = useAuth0();
  const [createUserAndOrg] = useCreateUserAndOrgMutation();

  const navigate = useNavigate();
  const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const toast = useToast();

  const validation = (data) => {
    if (data.orgName === "") {
      toast({
        title: "Error",
        description: "Organisation name cannot be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (data.address === "") {
      toast({
        title: "Error",
        description: "Address cannot be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (data.regNumber === "") {
      toast({
        title: "Error",
        description: "Registration number cannot be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (data.zipcode === "") {
      toast({
        title: "Error",
        description: "Zipcode cannot be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (data.state === "") {
      toast({
        title: "Error",
        description: "State cannot be empty",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!validation(inputData)) return;

    try {
      await createUserAndOrg({
        email: user.email,
        emailAddress: user.email,
        firstName: user.given_name,
        lastName: user.family_name,
        subClaim: user.sub,
        medicalRegistrationNo: inputData.regNumber,
        organizationName: inputData.orgName,
        Address: inputData.address,
        userType: "admin",
        state: inputData.state,
        pincode: inputData.zipcode,
        city: "",
        phoneNumber: "",
      }).unwrap();

      setInputData({
        orgName: "",
        address: "",
        regNumber: "",
        zipcode: "",
        state: "",
        userType: "admin",
      });

      navigate("/", { replace: true });
    } catch (err) {
      if (err.status === "FETCH_ERROR" || err.status >= 500) {
        toast({
          title: "Error",
          description: "Server down, try after some time",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Registration failed, Try again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex w="100%" h="100%">
      <Image w="41.875vw" h="100vh" pos="absolute" left="0" src={sideImage} />
      <Flex
        pos="absolute"
        left="41.875vw"
        w="58.125vw"
        h="100vh"
        bgColor="rgba(76, 76, 193, 0.03)"
      >
        <Flex pl="5vw" direction="column" pt="12vh">
          <VStack alignItems="flex-start">
            <Text fontSize={ifSmallScreen ? "30px" : "48px"} color="#3B5D7C">
              REGISTER ORGANISATION
            </Text>
          </VStack>
          <Flex w="45.67vw" h="50vh" mt="4vh" fontSize={14} direction="column">
            <VStack alignItems="flex-start" w="100%">
              <Text>Organisation Name</Text>
              <Input
                name="orgName"
                value={inputData.orgName}
                onChange={handleInput}
                borderRadius={0}
                _focus={{ outline: "none" }}
                bgColor="#fff"
                maxLength="46"
              />
            </VStack>

            <VStack w="100%" mt="4vh" alignItems="flex-start">
              <Text>Address</Text>
              <Input
                name="address"
                value={inputData.address}
                onChange={handleInput}
                borderRadius={0}
                _focus={{ outline: "none" }}
                bgColor="#fff"
                maxLength="46"
              />
            </VStack>
            <VStack w="100%" mt="4vh" alignItems="flex-start">
              <Text>Registration Number</Text>
              <Input
                name="regNumber"
                value={inputData.regNumber}
                onChange={handleInput}
                borderRadius={0}
                _focus={{ outline: "none" }}
                bgColor="#fff"
                maxLength="14"
              />
            </VStack>
            <HStack w="100%" justifyContent="space-between" mt="4vh">
              <VStack alignItems="flex-start" w="49%">
                <Text>Zip code</Text>
                <Input
                  name="zipcode"
                  value={inputData.zipcode}
                  onChange={handleInput}
                  type="number"
                  borderRadius={0}
                  _focus={{ outline: "none" }}
                  bgColor="#fff"
                  maxLength="8"
                />
              </VStack>
              <VStack alignItems="flex-start" w="49%">
                <Text>State</Text>
                <Input
                  name="state"
                  value={inputData.state}
                  onChange={handleInput}
                  borderRadius={0}
                  _focus={{ outline: "none" }}
                  bgColor="#fff"
                  maxLength="35"
                />
              </VStack>
            </HStack>
            <Flex mt="2.5vh" direction="column">
              <VStack alignItems="flex-start" pl="2vw" spacing="1vh">
                <Text>
                  Already have an account?{" "}
                  <Button
                    fontSize={14}
                    variant="link"
                    _focus={{ outline: "none" }}
                    onClick={() =>
                      logout({
                        returnTo: `${window.location.origin}/login`,
                      })
                    }
                  >
                    Sign In
                  </Button>
                </Text>
                <Checkbox>
                  <Text fontSize={14}>Subscribe to MedAI Newsletter</Text>
                </Checkbox>

                {/* TnC will be implemented later */}
                {/* <Checkbox defaultChecked disabled>
                  <Text fontSize={14}>
                    I agree to all the Terms & Conditions
                  </Text>
                </Checkbox> */}
              </VStack>
            </Flex>
            <Flex w="100%" justifyContent="center" mt="3vh">
              <Button
                borderRadius={0}
                bgColor="#3B5D7C"
                color="#FFF"
                fontWeight={400}
                onClick={handleSubmit}
              >
                Complete registration
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UserDetails;
