import { Box, Button, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";

const ViewerPermission = ({ onClose }) => {
  const [buttonColor1, setButtonColor1] = useState();
  const [buttonColor2, setButtonColor2] = useState();
  const [buttonColor3, setButtonColor3] = useState();
  const [buttonColor4, setButtonColor4] = useState();
  //   const permissions = [
  //     { name: "Add Manual Annotations" },
  //     { name: "Multi-Slide View" },
  //     { name: "Morphometry" },
  //     { name: "Save Image" },
  //   ];
  return (
    <Box fontSize="14px">
      {/* {permissions?.map((permisisionName) => {
        return ( */}
      <HStack
        minH="50px"
        borderBottom="1px solid #DEDEDE"
        px="1.1vw"
        justifyContent="space-between"
      >
        <Text>Add Manual Annotations</Text>
        <Button
          bg="none"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          borderRadius="0"
          border="1px solid rgba(33, 34, 36, 0.1)"
          color={buttonColor1 ? "#C60D00" : "#189B77"}
          fontWeight="400"
          fontSize="14px"
          h="2.96vh"
          minH="30px"
          fontFamily="inter"
          onClick={() => setButtonColor1(!buttonColor1)}
          minW="80px"
          w="80px"
        >
          {buttonColor1 ? "Disable" : "Enable"}
        </Button>
      </HStack>
      <HStack
        minH="50px"
        borderBottom="1px solid #DEDEDE"
        px="1.1vw"
        justifyContent="space-between"
      >
        <Text>Multi-Slide View</Text>
        <Button
          bg="none"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          borderRadius="0"
          border="1px solid rgba(33, 34, 36, 0.1)"
          color={buttonColor2 ? "#C60D00" : "#189B77"}
          fontWeight="400"
          fontSize="14px"
          h="2.96vh"
          minH="30px"
          fontFamily="inter"
          onClick={() => setButtonColor2(!buttonColor2)}
          minW="80px"
          w="80px"
        >
          {buttonColor2 ? "Disable" : "Enable"}
        </Button>
      </HStack>
      <HStack
        minH="50px"
        borderBottom="1px solid #DEDEDE"
        px="1.1vw"
        justifyContent="space-between"
      >
        <Text>Morphometry</Text>
        <Button
          bg="none"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          borderRadius="0"
          border="1px solid rgba(33, 34, 36, 0.1)"
          color={buttonColor3 ? "#C60D00" : "#189B77"}
          fontWeight="400"
          fontSize="14px"
          h="2.96vh"
          minH="30px"
          fontFamily="inter"
          onClick={() => setButtonColor3(!buttonColor3)}
          minW="80px"
          w="80px"
        >
          {buttonColor3 ? "Disable" : "Enable"}
        </Button>
      </HStack>
      <HStack
        minH="50px"
        borderBottom="1px solid #DEDEDE"
        px="1.1vw"
        justifyContent="space-between"
      >
        <Text>Save Image</Text>
        <Button
          bg="none"
          _hover={{ bg: "none" }}
          _focus={{ bg: "none" }}
          borderRadius="0"
          border="1px solid rgba(33, 34, 36, 0.1)"
          color={buttonColor4 ? "#C60D00" : "#189B77"}
          fontWeight="400"
          fontSize="14px"
          h="2.96vh"
          minH="30px"
          fontFamily="inter"
          onClick={() => setButtonColor4(!buttonColor4)}
          minW="80px"
          w="80px"
        >
          {buttonColor4 ? "Disable" : "Enable"}
        </Button>
      </HStack>
      <Button
        float="right"
        m="1.5vh 1vw 1vh 1vw"
        bg="#3B5D7C"
        _hover={{ bg: "#3B5D7C" }}
        _focus={{ bg: "none" }}
        borderRadius="0"
        border="1px solid #3B5D7C"
        color="#fff"
        fontSize="14px"
        h="32px"
        minH="30px"
        fontFamily="inter"
        fontWeight="500"
        minW="100px"
        w="100px"
        onClick={onClose}
      >
        Save
      </Button>
      {/* );
      })} */}
    </Box>
  );
};

export default ViewerPermission;
