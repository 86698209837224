import React from "react";
import { Flex, HStack, VStack, Box, useMediaQuery } from "@chakra-ui/react";
import Overview from "./OverView";
import PatientDetails from "./PatientDetails";
import RecentSlides from "./RecentSlides";
import CaseClinicalHistory from "./CaseClinicalHistory";
import CaseTimeline from "./CaseTimeline";
import Orders from "./Orders";
import RadiologyImages from "./RadiologyImages";
import RecentChats from "./RecentChats";
import GrossImages from "./GrossImages";

const CaseOverview = ({
  caseInfo,
  setTabIndex,
  notifyOpenSlide,
  setNotifyOpenSlide,
}) => {
  const [ifWidthLargethanthan1800] = useMediaQuery("(min-width:1800px)");
  return (
    <Flex h="100%" w="100%" bgColor="light.1004">
      <Flex w="45%" h="100%" direction="column" pr="15px" pt="10px" pl="10px">
        <HStack w="100%" spacing={4}>
          <Flex w="49%">
            <PatientDetails caseInfo={caseInfo} />
          </Flex>
          <Flex w="51%">
            <Overview caseInfo={caseInfo} />
          </Flex>
        </HStack>
        <HStack mt="10px" spacing={4}>
          <Box w="48%">
            <GrossImages caseInfo={caseInfo} setTabIndex={setTabIndex} />
          </Box>
          <Box w="49%">
            <RadiologyImages caseInfo={caseInfo} setTabIndex={setTabIndex} />
          </Box>
        </HStack>
        {/* <RecentSlides caseInfo={caseInfo} caseId={caseInfo?._id} /> */}
      </Flex>
      <VStack w="31%" pt="10px" mr="15px" pb="15px">
        <CaseClinicalHistory caseInfo={caseInfo} setTabIndex={setTabIndex} />
        <RecentSlides
          notifyOpenSlide={notifyOpenSlide}
          setNotifyOpenSlide={setNotifyOpenSlide}
          caseInfo={caseInfo}
          caseId={caseInfo?._id}
          setTabIndex={setTabIndex}
        />
        {/* <Orders /> */}
      </VStack>
      <VStack
        w="22%"
        h={ifWidthLargethanthan1800 ? "800px" : "678px"}
        pt="10px"
      >
        {/* <RecentChats userInfo={userInfo}/> */}
        <CaseTimeline caseInfo={caseInfo} setTabIndex={setTabIndex} />
      </VStack>
    </Flex>
    // <Box h="100%" w="100%" bgColor="light.1004">
    //   <HStack w="39%" h="100%">
    //     <PatientDetails caseInfo={caseInfo} />
    //     <Overview caseInfo={caseInfo} />
    //   </HStack>
    //   <HStack w="45%">
    //     <GrossImages caseInfo={caseInfo} />
    //     <Flex h="50%" w="30%">
    //       <RadiologyImages caseInfo={caseInfo} />
    //     </Flex>
    //   </HStack>
    //   <HStack w="39%" h="100%">
    //     c
    //   </HStack>
    // </Box>
  );
};
export default CaseOverview;
