import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import HospitalApiSlice from "./API/HospitalApi";
import authReducer from "./Reducers/authReducer";
import newCaseReducer from "./Reducers/newCaseReducer";

const store = configureStore({
  reducer: {
    authState: authReducer,
    newCaseState: newCaseReducer,
    [HospitalApiSlice.reducerPath]: HospitalApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(HospitalApiSlice.middleware),
});

setupListeners(store.dispatch);

export default store;
