import { Flex, Text, useMediaQuery, Image } from "@chakra-ui/react";
import React from "react";
import { TbUrgent } from "react-icons/tb";
import moment from "moment";
import { MdPendingActions } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useGetCountOfCasesQuery } from "../../state/API/HospitalApi";
import NewCaseIcon from "../../Static/Images/NewIcon.png";

const InfoCard = ({
  background,
  headingColor,
  statColor,
  heading,
  number,
  setCaseStatus,
}) => {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  return (
    <Flex
      w="24%"
      h="100%"
      bgColor={background}
      p={ifWidthLessthan1600 ? "14px" : "18px"}
      borderRadius="15px"
      mr="1%"
      onClick={() =>
        setCaseStatus(
          heading === "Urgent"
            ? "urgent"
            : heading === "New"
            ? "new"
            : heading === "Pending"
            ? "pending"
            : heading === "Completed"
            ? "reported"
            : ""
        )
      }
      cursor="pointer"
    >
      <Flex direction="column" w="80%" h="100%">
        <Text
          fontSize={ifWidthLessthan1600 ? "16px" : "23px"}
          color={headingColor}
          h="25%"
        >
          {heading}
        </Text>
        <Flex>
          {heading === "Urgent" ? (
            <Flex
              h="100%"
              w="30%"
              justifyContent="flex-end"
              alignItems="center"
            >
              <TbUrgent color={statColor} size="100%" />
            </Flex>
          ) : null}
          {heading === "Pending" ? (
            <Flex
              h="100%"
              w="30%"
              justifyContent="flex-end"
              alignItems="center"
            >
              <MdPendingActions color={statColor} size="100%" />
            </Flex>
          ) : null}
          {heading === "New" ? (
            <Flex
              h="100%"
              w="30%"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <MdOutlineFiberNew color={statColor} size="100%" /> */}
              <Image src={NewCaseIcon} />
            </Flex>
          ) : null}
          {heading === "Completed" ? (
            <Flex
              h="100%"
              w="30%"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <MdOutlineFiberNew color={statColor} size="100%" /> */}
              <AiOutlineCheckCircle color={statColor} size="100%" />
            </Flex>
          ) : null}
          <Text
            h="75%"
            fontSize="8vh"
            fontWeight="600"
            color={statColor}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {number}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const UserStatistics = ({ filterData, setCaseStatus }) => {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  const { data: caseCount } = useGetCountOfCasesQuery();

  return (
    <Flex w="100%" height="20%" direction="column">
      <Flex w="100%" h="100%" justifyContent="space-between">
        <InfoCard
          background="#F8F2F0"
          headingColor="rgba(208, 103, 104, 0.85)"
          statColor="#D06768"
          heading="Urgent"
          number={caseCount?.data?.urgentCases}
          setCaseStatus={setCaseStatus}
        />

        <InfoCard
          background="#F6F4FE"
          headingColor="#1B75BC"
          statColor="#1B75BC"
          heading="New"
          number={caseCount?.data?.newCases}
          setCaseStatus={setCaseStatus}
        />
        <InfoCard
          background="#FFF6EA"
          headingColor="#FF9900"
          statColor="#FF9900"
          heading="Pending"
          number={caseCount?.data?.pendingCases}
          setCaseStatus={setCaseStatus}
        />

        <InfoCard
          background="#FEF4FE"
          headingColor="#B451B4"
          statColor="#B451B4"
          heading="Total"
          number={caseCount?.data?.totalCases}
          setCaseStatus={setCaseStatus}
        />
        <InfoCard
          background="#F4F6FE"
          headingColor="rgba(63, 98, 203, 0.85)"
          statColor="#3F62CB"
          heading="Completed"
          number={caseCount?.data?.reportedCases}
          setCaseStatus={setCaseStatus}
        />
      </Flex>
    </Flex>
  );
};

export default UserStatistics;
