import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const Loading = (restProps) => (
  <Flex pos="fixed" top="50%" left="50%" transform="translate(-50%, -50%)">
    <Spinner
      color="#3965C5"
      size="xl"
      thickness="4px"
      speed="0.65s"
      {...restProps}
    />
  </Flex>
);

export default Loading;
