import React from "react";
import { Navigate } from "react-router-dom";
import { useGetUserInfoQuery } from "../../state/API/HospitalApi";
import Loading from "../Loading/loading";
import { PageNotFound } from "../ErrorPages";

const DashboardRedirect = () => {
  const { data: userInfo, isLoading } = useGetUserInfoQuery();

  if (isLoading) return <Loading />;

  if (userInfo)
    return <Navigate to={`/dashboard/${userInfo.userType}`} replace />;

  return <PageNotFound />;
};

export default DashboardRedirect;
