import axios from "axios";
import { useLazyQuery, useSubscription, useMutation } from "@apollo/client";

import {
  ANNOTATIONS_SUBSCRIPTION,
  DELETE_ANNOTATION,
  GET_ANNOTATION,
  GET_TILS_ANALYSIS,
  GET_VHUT_ANALYSIS,
  GET_XMLANNOTATION,
  SAVE_ANNOTATION,
  TIL_ANALYSIS_SUBSCRIPTION,
  TUMOR_ANALYSIS,
  TUMOR_DETECTION_SUBSCRIPTION,
  UPDATE_ANNOTATION,
  VHUT_ANALTSIS,
  VHUT_ANALYSIS_SUBSCRIPTION,
  VHUT_VIEWPORT_ANALYSIS,
} from "../state/API/graphql/AnnotationQuery";
import {
  FILTER_DATA,
  GET_ADJUSTMENT_RESULT,
} from "../state/API/graphql/FilterQuery";
import {
  CHAT_SUBSCRIPTION,
  FETCH_CONVERSATION,
  SEND_MESSAGE,
} from "../state/API/graphql/ViewerChatQuery";
import { apolloClient } from "./chatApolloConfig";

export const onDownloadFile = (key, bucketName) => {
  const requestBody = {
    key,
    bucket_name: bucketName,
  };
  // console.log(requestBody);
  axios.post(
    "https://development-morphometry-api.prr.ai/quantize/v1/download",
    requestBody
  );
};

export const useGetAnnotation = () => {
  const [getAnnotation, { data: annotationData, loading, error }] =
    useLazyQuery(GET_ANNOTATION);

  return { getAnnotation, annotationData, loading, error };
};

export const useGetXMLAnnotation = () => {
  const [getXmlAnnotation, { data: xmlAnnotationData }] =
    useLazyQuery(GET_XMLANNOTATION);

  return { getXmlAnnotation, xmlAnnotationData };
}; // not written in documentation

export const useVhutSubscription = (slideId) => {
  const { data: vhutSubscriptionData, error: vhutSubscriptionError } =
    useSubscription(VHUT_ANALYSIS_SUBSCRIPTION, {
      variables: {
        body: {
          slideId,
        },
      },
    });

  return { vhutSubscriptionData, vhutSubscriptionError };
};

export const useAnnotationSubscription = (slideId) => {
  const { data: subscriptionData, error: subscriptionError } = useSubscription(
    ANNOTATIONS_SUBSCRIPTION,
    {
      variables: {
        slideId,
      },
    }
  );
  return { subscriptionData, subscriptionError };
};

export const useGetFilterData = () => {
  const [
    onGetFilterData,
    { data: filterResponseData, error: filterResponseError },
  ] = useLazyQuery(GET_ADJUSTMENT_RESULT);
  return { onGetFilterData, filterResponseData, filterResponseError };
};

export const useVhutAnalysisMutation = () => {
  const [onVhutAnalysis, { data: analysisData, error: analysisError }] =
    useMutation(VHUT_ANALTSIS);

  return { onVhutAnalysis, analysisData, analysisError };
};

export const useGetVhutAnalysisData = () => {
  const [onGetVhutAnalysis, { data: responseData, error: responseError }] =
    useLazyQuery(GET_VHUT_ANALYSIS);
  return { onGetVhutAnalysis, responseData, responseError };
};

export const useUpdateAnnotation = () => {
  const [
    modifyAnnotation,
    { data: updatedData, error: updateError, loading: updateLoading },
  ] = useMutation(UPDATE_ANNOTATION);

  return { modifyAnnotation, updatedData, updateError };
};

export const useDeleteAnnotation = () => {
  const [removeAnnotation, { data: deletedData, error: deleteError }] =
    useMutation(DELETE_ANNOTATION);

  return { removeAnnotation, deletedData, deleteError };
};

export const useOnTumorGleasonAnalysis = () => {
  const [onTumorAnalysis, { data: analysisData, error: analysisError }] =
    useMutation(TUMOR_ANALYSIS);

  return { onTumorAnalysis, analysisData, analysisError };
};

export const useGetTumorGleasonAnalysis = (slideId, modelType) => {
  const { data: subscription, error: subscriptionError } = useSubscription(
    TUMOR_DETECTION_SUBSCRIPTION,
    {
      variables: {
        body: {
          data: {
            slideid: slideId,
            type: modelType,
          },
        },
      },
    }
  );
  return { subscription, subscriptionError };
};

export const useGetTILAnalysis = () => {
  const [getTils, { data, loading, error, refetch }] =
    useLazyQuery(GET_TILS_ANALYSIS);

  return { getTils, data, error, refetch, loading };
};

export const useGetHILAnalysis = () => {
  const [
    getTils1,
    { data: data1, loading: loading1, error: error1, refetch: refetch1 },
  ] = useLazyQuery(GET_TILS_ANALYSIS);

  return { getTils1, data1, error1, refetch1, loading1 };
};

export const useGetTILSubscription = (slideId) => {
  const { data: tilSubscriptionData, error: vhutSubscriptionError } =
    useSubscription(TIL_ANALYSIS_SUBSCRIPTION, {
      variables: {
        body: {
          slideId,
        },
      },
    });
  return { tilSubscriptionData, vhutSubscriptionError };
};

export const useCreateAnnotation = () => {
  const [createAnnotation, { error: annotationError, loading }] =
    useMutation(SAVE_ANNOTATION);

  return { createAnnotation, annotationError, loading };
};

export const useSaveFilterData = () => {
  const [onFilterData, { data: analysisData, error: analysisError }] =
    useMutation(FILTER_DATA);

  return { onFilterData, analysisData, analysisError };
};

export const useVhutViewportAnalysis = () => {
  const [onVhutViewportAnalysis] = useMutation(VHUT_VIEWPORT_ANALYSIS);
  return { onVhutViewportAnalysis };
};

export const useGetChatMessages = () => {
  const [
    fetchMessages,
    { loading: isConversationLoading, refetch, data: msgData, error },
  ] = useLazyQuery(FETCH_CONVERSATION, {
    client: apolloClient, // Use the apolloClient instance
  });

  return { fetchMessages, isConversationLoading, msgData, refetch, error };
};

export const useSendMessages = () => {
  const [sendNewMessage, { error: newMessageError }] = useMutation(
    SEND_MESSAGE,
    {
      client: apolloClient, // Use the apolloClient instance
    }
  );

  return { sendNewMessage };
};

export const useGetMessageSubcribedData = (groupChatId, userData) => {
  console.log(groupChatId, userData);
  const { data: subscribedMessageData, error: errorMessage } = useSubscription(
    CHAT_SUBSCRIPTION,
    {
      variables: {
        toId: groupChatId,
        fromId: userData,
      },
      client: apolloClient,
    }
  );
  return { subscribedMessageData, errorMessage };
};
