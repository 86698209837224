/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from "react";
import {
  Flex,
  Text,
  useMediaQuery,
  Image,
  Button,
  HStack,
  Box,
} from "@chakra-ui/react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { BiLinkExternal } from "react-icons/bi";

const GrossImages = ({ caseInfo, setTabIndex }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const [ifWidthLargethanthan1800] = useMediaQuery("(min-width:1800px)");
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <Flex
      h="36vh"
      minH={ifWidthLargethanthan1800 ? "425px" : "350px"}
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Gross Images
        </Text>
        <BiLinkExternal
          size={18}
          style={{ color: "#1B75BC", cursor: "pointer" }}
          onClick={() => setTabIndex(3)}
        />
      </HStack>
      {caseInfo?.gross?.grossImageURLs?.filter((obj) => obj.isDeleted === false)
        .length > 0 ? (
        <Flex w="100%" h="100%" direction="column">
          <Flex
            h="75%"
            w="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              bgColor="inherit"
              _hover={{ bgColor: "inherit" }}
              _active={{ bgColor: "inherit" }}
              disabled={imageIndex === 0}
              onClick={() => setImageIndex(imageIndex - 1)}
            >
              <GrPrevious size={30} />
            </Button>
            {/* <Image
              src={
                caseInfo?.gross?.grossImageURLs?.filter(
                  (obj) => obj.isDeleted === false
                )[imageIndex]?.docUrl
              }
              // h="65%"
              w="65%"
              height="auto"
            /> */}
            <Box width="150px" height="150px">
              <Image
                src={
                  caseInfo?.gross?.grossImageURLs?.filter(
                    (obj) => obj.isDeleted === false
                  )[imageIndex]?.docUrl
                }
                alt="Image description"
                width="100%"
                height="100%"
                objectFit="contain"
                css={{ cursor: "-webkit-grab" }}
              />
            </Box>
            <Button
              bgColor="inherit"
              _hover={{ bgColor: "inherit" }}
              _active={{ bgColor: "inherit" }}
              disabled={
                imageIndex ===
                caseInfo?.gross?.grossImageURLs?.filter(
                  (obj) => obj.isDeleted === false
                ).length -
                  1
              }
              onClick={() => setImageIndex(imageIndex + 1)}
            >
              <GrNext size={30} />
            </Button>
          </Flex>
          <Flex direction="column" p="0px 0.5vw">
            {/* <Text fontSize={ifWidthLessthan1920 ? "14px" : "16px"}> */}
            <Text fontSize={ifWidthLessthan1920 ? "12px" : "14px"}>
              {
                caseInfo?.gross?.grossImageURLs?.filter(
                  (obj) => obj.isDeleted === false
                )[imageIndex]?.docName
              }
            </Text>
            <Text
              fontSize={ifWidthLessthan1920 ? "10px" : "12px"}
              opacity="0.7"
              mt="4px"
            >
              Description
            </Text>
            <Text
              fontSize={ifWidthLessthan1920 ? "12px" : "14px"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {" "}
              {
                caseInfo?.gross?.grossImageURLs?.filter(
                  (obj) => obj.isDeleted === false
                )[imageIndex]?.docDescription
              }
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Text opacity="0.3">No gross images uploaded</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default GrossImages;
