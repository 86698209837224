import React, { useEffect, useState } from "react";
import {
  Flex,
  useMediaQuery,
  Text,
  VStack,
  HStack,
  Button,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HiOutlinePencilAlt } from "react-icons/hi";
import moment from "moment";
import axios from "axios";
import Environment from "../../../environment";
import ScrollBar from "../../other/Scrollbar";

const PatientDetails = ({ caseInfo }) => {
  const toast = useToast();
  const [isEditActive, setIsEditActive] = useState(false);
  const [inputData, setInputData] = useState({
    patientName: "",
    gender: "",
    dob: "",
    address: "",
    UHID: "",
    contactNo: "",
  });

  useEffect(() => {
    if (caseInfo) {
      setInputData({
        patientName: `${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`,
        gender: caseInfo?.patient?.gender,
        dob: `${caseInfo?.patient?.dateOfBirth}`,
        contactNo: caseInfo?.patient?.contactNumber
          ? caseInfo?.patient?.contactNumber
          : "NA",
        address: caseInfo?.patient?.patientAddress
          ? caseInfo?.patient?.patientAddress
          : "NA",
        UHID: caseInfo?.patient?.uhid,
      });
    }
  }, caseInfo);

  const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const resp = await axios.put(
      `${Environment.USER_URL}/edit_patient_info?_id=${caseInfo?._id}`,
      {
        patientName: { firstName: inputData.patientName, lastName: "" },
        gender: inputData.gender,
        dateOfBirth: inputData.dob,
        contactNumber: inputData.contactNo,
        patientAddress: inputData.address,
        uhid: inputData.UHID,
      }
      // {
      //   patientName: { firstName: "test", lastName: "" },
      //   gender: "male",
      //   dateOfBirth: "1955-06-22",
      //   contactNumber: "+1 (338) 499-8399",
      //   patientAddress:
      //     "aksjdfajsdfj asdjf lkajsdflkjsadlkjfkl ajsdlfk jaslkdjf",
      //   uhid: "38474839384848",
      // }
    );
    if (resp.status === 200) {
      toast({
        title: "Patient details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => window.location.reload(), 1500);
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "11px" : "13px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "18px",
    fontFamily: "Inter",
  };
  return isEditActive ? (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
      pos="relative"
    >
      <Text
        color="#1B75BC"
        fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
        fontWeight="600"
      >
        Patient overview
      </Text>

      <ScrollBar>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          alignItems="flex-end"
          spacing={ifWidthLessthan1920 ? "8px" : "15px"}
          mt="10px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Name</Text>
              <Input
                defaultValue={`${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`}
                variant="flushed"
                h="30px"
                name="patientName"
                onChange={handleInput}
              />
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Gender</Text>
              <Select
                id="gender"
                name="gender"
                h="30px"
                placeholder="select gender"
                borderRadius={5}
                required
                variant="unstyled"
                defaultValue={inputData.gender}
                onChange={handleInput}
              >
                <option hidden disabled value="">
                  Select option
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="non binary">Non Binary</option>
              </Select>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Date of birth</Text>
              <Input
                type="date"
                id="age"
                name="dob"
                onChange={(e) => handleInput(e)}
                max="9999-12-31"
                value={inputData.dob}
                h="30px"
                borderRadius={5}
                onWheel={(e) => e.target.blur()}
                maxLength="2"
                required
              />
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Contact No.</Text>
              <PhoneInput
                name="contactNo"
                country="us"
                inputProps={{
                  name: "patientContactNumber",
                  required: true,
                  autoFocus: true,
                  id: "contact_number",
                }}
                enableSearch="true"
                placeholder="Enter phone number"
                value={inputData.contactNo}
                onChange={(country, formattedValue, value, e) => {
                  setInputData({ ...inputData, contactNo: e });
                }}
                style={{
                  width: "50px",
                  height: "27px",
                }}
                buttonStyle={{ height: "29px" }}
                containerStyle={{
                  border: "10px solid black",
                }}
                inputStyle={{ width: "9vw", height: "30px" }}
              />
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Address</Text>
              <Textarea
                h="50px"
                name="address"
                onChange={handleInput}
                defaultValue={
                  caseInfo?.patient?.patientAddress
                    ? caseInfo?.patient?.patientAddress
                    : "NA"
                }
              />
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>UHID</Text>
              <Input
                defaultValue={caseInfo?.patient?.uhid}
                variant="flushed"
                h="30px"
                name="UHID"
                onChange={handleInput}
              />
            </VStack>
          </HStack>
          <HStack pos="absolute" bottom="5px">
            <Button
              onClick={() => setIsEditActive(false)}
              color="#1B75BC"
              bgColor="inherit"
              fontSize="14px"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bgColor="#C6DDEE"
              color="#1B75BC"
              fontSize="14px"
              w="100px"
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </ScrollBar>
    </Flex>
  ) : (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Patient overview
        </Text>
        <Button bgColor="inherit" onClick={() => setIsEditActive(true)}>
          <HiOutlinePencilAlt color="#1B75BC" />
        </Button>
      </HStack>

      <ScrollBar>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          justifyContent="flex-start"
          spacing={ifWidthLessthan1920 ? "8px" : "15px"}
          mt="10px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Name</Text>
              <Text
                style={infoStyle}
              >{`${caseInfo?.patient?.patientName?.firstName} ${caseInfo?.patient?.patientName?.lastName}`}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Gender</Text>
              <Text style={infoStyle}>{caseInfo?.patient?.gender}</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Age</Text>
              <Text style={infoStyle}>
                {`${moment().diff(
                  `${caseInfo?.patient?.dateOfBirth}`,
                  "years"
                )} Y`}
              </Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Contact No.</Text>
              <Text style={infoStyle}>
                {caseInfo?.patient?.contactNumber
                  ? caseInfo?.patient?.contactNumber
                  : "NA"}
              </Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Address</Text>
              <Text style={infoStyle}>
                {caseInfo?.patient?.patientAddress
                  ? caseInfo?.patient?.patientAddress
                  : "NA"}
              </Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>UHID</Text>
              <Text style={infoStyle}>{caseInfo?.patient?.uhid}</Text>
            </VStack>
          </HStack>
        </VStack>
      </ScrollBar>
    </Flex>
  );
};

export default PatientDetails;
