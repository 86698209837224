import React from "react";
import {
  Box,
  Input,
  Text,
  HStack,
  VStack,
  Textarea,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";

const UploadClinicalHistory = () => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");

  return (
    <Box fontFamily="inter" fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}>
      <Box>
        <HStack pl="2.0833vw">
          <VStack align="left">
            <Text
              htmlFor="image_title"
              color="#000"
              paddingBottom="0.729vw"
              paddingTop={6}
            >
              Image Title*
            </Text>
            <Input
              type="text"
              id="image_title"
              name="imageTitle"
              width={350}
              height={42}
              ml="1.458vw"
              borderRadius={0}
              bg="light.100"
              borderColor="#000"
              placeholder="Enter Date"
              required
            />
          </VStack>
          <VStack pl={5} align="left">
            <Text
              htmlFor="contact_details"
              color="#000"
              paddingBottom="0.729vw"
              paddingTop={6}
            >
              Contact Details
            </Text>
            <Input
              type="text"
              id="contact_details"
              name="contactDetails"
              width={350}
              height={42}
              ml="1.458vw"
              borderRadius={0}
              bg="light.100"
              borderColor="#000"
              placeholder="Enter Contact"
              required
            />
          </VStack>
        </HStack>
        <Text
          htmlFor="upload_image"
          paddingTop={6}
          color="#000"
          paddingBottom="0.833vw"
          ml="2.0833vw"
        >
          Upload Files
        </Text>
        <Box
          width={728}
          ml="2.0833vw"
          h={12}
          border="2px dashed #000"
          borderRadius="0px"
          bgColor="light.100"
        >
          <Text fontSize="0.729vw" pl="15.02vw" pt="1.2vh">
            Click here to upload Images
          </Text>
          <Input
            id="upload_images"
            name="uploadImages"
            css={{ position: "relative", opacity: 0 }}
            height={42}
            top="0"
            bg="light.100"
            borderRadius={0}
            borderColor="#000"
            resize="none"
            type="file"
          />
        </Box>
        <Text
          htmlFor="Preview_images"
          paddingTop={6}
          color="#000"
          fontSize="0.729vw"
          paddingBottom="0.833vw"
          ml="2.0833vw"
        >
          Preview Images
        </Text>
        <Box
          id="Preview_images"
          name="PreviewImages"
          width={728}
          height={90}
          top="0"
          ml="2.0833vw"
          bg="light.100"
          borderRadius={0}
          borderColor="#000"
          border="1px solid #000"
        />
        <Text
          htmlFor="details"
          paddingTop={6}
          color="#000"
          paddingBottom="0.833vw"
          ml="2.0833vw"
        >
          Details
        </Text>
        <Textarea
          id="details"
          name="details"
          width={728}
          height={100}
          top="0"
          ml="2.0833vw"
          bg="light.100"
          borderRadius={0}
          borderColor="#000"
          resize="none"
          placeholder="Add description"
        />
      </Box>
      <Box pl="2.083vw" pb="0.937vw" pt="1.458vw">
        <Button
          width={150}
          fontWeight="600"
          // onClick={handleReset}
          border="1px solid #00153F"
          borderRadius={0}
          color="light.400"
        >
          Reset
        </Button>
        <Button
          bg="light.400"
          color="white"
          width={150}
          fontWeight="600"
          ml={6}
          borderRadius={0}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default UploadClinicalHistory;
