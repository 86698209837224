import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { MedViewer } from "med-viewer";
import {
  useSaveReportMutation,
  useMultiFileUploadMutation,
  useLazyGetSlideInfoQuery,
  useSaveSynopticReportMutation,
  useLazyGetSynopticReportQuery,
  useGetUsersListQuery,
  useUpdateSynopticReportMutation,
  useAddMultipleUserToCaseMutation,
} from "../../state/API/HospitalApi";
import Loading from "../Loading/loading";
import Environment from "../../environment";
import Header from "../../DashboardLayout/Header";
import {
  onDownloadFile,
  useGetAnnotation,
  useVhutSubscription,
  useGetXMLAnnotation,
  useAnnotationSubscription,
  useGetFilterData,
  useVhutAnalysisMutation,
  useGetVhutAnalysisData,
  useUpdateAnnotation,
  useDeleteAnnotation,
  useOnTumorGleasonAnalysis,
  useGetTumorGleasonAnalysis,
  useGetTILAnalysis,
  useGetHILAnalysis,
  useGetTILSubscription,
  useCreateAnnotation,
  useSaveFilterData,
  useVhutViewportAnalysis,
  useGetChatMessages,
  useSendMessages,
  useGetMessageSubcribedData,
} from "../../viewerUtility/utility";

const OSDViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, caseInfo, slide } = location.state;
  // console.log(getVhutSubscription);

  // ############# PACKAGE NECESSARY HOOKS ######################

  const getAnnotationFromDB = useGetAnnotation();
  const getXMLAnnotationFromDB = useGetXMLAnnotation();
  const getFilterDataFromDB = useGetFilterData();
  const getVhutAnalysisData = useGetVhutAnalysisData();
  const onUpdateVhutAnalysis = useVhutAnalysisMutation();
  const onUpdateAnnotation = useUpdateAnnotation();
  const onDeleteAnnotationFromDB = useDeleteAnnotation();
  const onGetTumorGleasonAnalysis = useOnTumorGleasonAnalysis();
  const onGetTILAnalysis = useGetTILAnalysis();
  const onGetHILAnalysis = useGetHILAnalysis();
  const onCreateAnnotation = useCreateAnnotation();
  const onSaveFilterData = useSaveFilterData();
  const onVhutViewportAnalysis = useVhutViewportAnalysis(); //
  const getMessagesFromDB = useGetChatMessages();
  const onSendMessage = useSendMessages();

  const useVhutSubscriptionData = (slideId) => {
    return useVhutSubscription(slideId);
  };
  const useAnnotationSubscriptionData = (slideId) => {
    return useAnnotationSubscription(slideId);
  };
  const useGetTumorGleasonResult = (slideId, modelType) => {
    return useGetTumorGleasonAnalysis(slideId, modelType);
  };
  const useGetTILSubscriptionData = (slideId) => {
    return useGetTILSubscription(slideId);
  };

  const useGetMessageSubcription = (groupChatId, userData) => {
    return useGetMessageSubcribedData(groupChatId, userData);
  };

  // ############ END ###################

  const [saveReport] = useSaveReportMutation();
  const [saveSynopticReport] = useSaveSynopticReportMutation();
  const [onSignatureUpload] = useMultiFileUploadMutation();
  const [slideInfo] = useLazyGetSlideInfoQuery();
  const [getSynopticReport] = useLazyGetSynopticReportQuery();
  const [updateSynopticReport] = useUpdateSynopticReportMutation();
  const [addUsersToCase] = useAddMultipleUserToCaseMutation();
  const [onSearchSelectedData, setOnSearchSelectedData] = useState("");
  // client 2
  const token = localStorage.getItem(Environment?.AUTH0_TOKEN);
  let accessToken;
  if (token) {
    if (
      localStorage.getItem(Environment?.AUTH0_TOKEN) ===
      Environment?.AUTH0_TOKEN
    ) {
      // console.log("YEEESSS");
    } else {
      // console.log("NOOOO");
    }
    const { body } = JSON.parse(token);
    if (body && typeof body === "object") {
      accessToken = body.access_token;
    }
  }
  const httpLink2 = new HttpLink({
    uri: "https://development-api.chat.prr.ai",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const wsLink2 = new GraphQLWsLink(
    createClient({
      url: "wss://development-api.chat.prr.ai",
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  const splitLink2 = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink2,
    httpLink2
  );
  const apolloClient2 = new ApolloClient({
    link: splitLink2,
    cache: new InMemoryCache(),
  });

  const { data: mentionUsers = [], isLoading: isUsersListLoading } =
    useGetUsersListQuery("");

  return !caseInfo ? (
    <Loading />
  ) : (
    <Flex direction="column" h="100vh">
      <Header userInfo={userInfo} />
      {/* <ApolloProvider client={apolloClient}> */}
      <MedViewer
        userInfo={userInfo}
        caseInfo={caseInfo}
        tile={slide?.awsImageBucketUrl}
        slides={caseInfo?.slides}
        viewerIds={[slide]}
        onDownloadFile={onDownloadFile}
        annotations
        slide={slide}
        report
        enableAI
        client2={apolloClient2}
        enableFilters
        application="hospital"
        onSaveReport={saveReport}
        onSaveSynopticReport={saveSynopticReport}
        onSignatureUpload={onSignatureUpload}
        onGetSlideInfo={slideInfo}
        onGetSynopticReport={getSynopticReport}
        onMentionUsers={mentionUsers}
        Environment={Environment}
        updateSynopticReport={updateSynopticReport}
        onAddUsersToCase={addUsersToCase}
        onSearchSelectedData={onSearchSelectedData}
        getAnnotationFromDB={getAnnotationFromDB}
        getVhutSubscriptionData={useVhutSubscriptionData}
        getXMLAnnotationFromDB={getXMLAnnotationFromDB}
        getAnnotationSubscriptionData={useAnnotationSubscriptionData}
        getFilterDataFromDB={getFilterDataFromDB}
        onUpdateVhutAnalysis={onUpdateVhutAnalysis}
        getVhutAnalysisData={getVhutAnalysisData}
        onModifyAnnotation={onUpdateAnnotation}
        onDeleteAnnotationFromDB={onDeleteAnnotationFromDB}
        onGetTumorGleasonAnalysis={onGetTumorGleasonAnalysis}
        getTumorGleasonResult={useGetTumorGleasonResult}
        onGetTILAnalysis={onGetTILAnalysis}
        onGetHILAnalysis={onGetHILAnalysis}
        getTILSubscriptionData={useGetTILSubscriptionData}
        onCreateAnnotation={onCreateAnnotation}
        onSaveFilterData={onSaveFilterData}
        onVhutViewportAnalysisMutation={onVhutViewportAnalysis}
        getMessagesFromDB={getMessagesFromDB}
        onSendMessage={onSendMessage}
        useGetMessageSubcription={useGetMessageSubcription}
      />
      {/* </ApolloProvider> */}
    </Flex>
  );
};

export default OSDViewer;
