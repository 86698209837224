import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../Loading/loading";

const Login = () => {
  const { isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, loginWithRedirect]);

  return <Loading />;
};

export default Login;
