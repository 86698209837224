import React from "react";
import { Flex, HStack } from "@chakra-ui/react";
import { BsFillCircleFill } from "react-icons/bs";
import NewClinicalHistory from "./NewClinicalHistory";
import GrossDetails from "./GrossDetails";
import RadiologyDetails from "./RadiologyDetails";

const UploadFiles = ({
  getFile,
  getFile1,
  getFile2,
  setIndex,
  setFileData,
  fileData,
  fileDataClinical,
  setFileDataClinical,
  fileDataGross,
  setFileDataGross,
}) => {
  return (
    <Flex direction="column" mt="2vh">
      <Flex w="100%" justifyContent="center" mb="10px">
        <HStack spacing="26px">
          <BsFillCircleFill
            size={17}
            color="#D9D9D9"
            style={{ cursor: "pointer" }}
            onClick={() => setIndex(0)}
          />
          <BsFillCircleFill
            size={17}
            color="#D9D9D9"
            style={{ cursor: "pointer" }}
            onClick={() => setIndex(1)}
          />
          <BsFillCircleFill
            size={17}
            color="rgba(27, 117, 188, 0.43"
            style={{ cursor: "pointer" }}
          />
        </HStack>
      </Flex>
      <NewClinicalHistory
        getFile={getFile}
        fileDataClinical={fileDataClinical}
        setFileDataClinical={setFileDataClinical}
      />
      <GrossDetails
        getFile2={getFile2}
        fileDataGross={fileDataGross}
        setFileDataGross={setFileDataGross}
      />
      <RadiologyDetails
        getFile1={getFile1}
        setFileData={setFileData}
        fileData={fileData}
      />
    </Flex>
  );
};

export default UploadFiles;
