import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Case from "../Case/Case";
import Login from "../Components/Authenticate/Login";
import UserDetails from "../Components/Authenticate/userDetails";
import DashboardRedirect from "../Components/DashboardRedirect/DashboardRedirect";
import ViewerRedirect from "../Components/Viewer/ViewerRedirect";
import OSDViewer from "../Components/Viewer/OSDViewer";
import CreateCase from "../NewCase/CreateCase";
import AppContainer from "../AppContainer";
import PublicRoute from "./PublicRoute";
import roles from "../configs/role.config";
import { PageNotFound } from "../Components/ErrorPages";
import AdminDashboard from "../DashboardLayout/AdminDashboard";
import AddMemberDashboard from "../DashboardLayout/AddMember/AddmemberDashboard";
import RestrictedRoute from "./RestrictedRoute";
import { DashboardLayout } from "../Components/Dashboard";
import Chat from "../Features/Chats/Chat";
import CaseInfoScreen from "../Components/NewDashboard/CaseInfoScreen";
import { ChatProvider } from "../Components/ChatApplication";
import DatagridTest from "../Components/NewDashboard/DatagridTest";

const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/:id/case/:caseId" element={<Case />} />

      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/registration"
        element={
          <RestrictedRoute>
            <UserDetails />
          </RestrictedRoute>
        }
      />

      <Route
        element={
          <ProtectedRoute
            allowedRoles={[roles.Admin, roles.Pathologist, roles.Technologist]}
          >
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/dashboard/admin"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/pathologist"
          element={
            <ProtectedRoute allowedRoles={[roles.Pathologist]}>
              <ChatProvider>
                <AppContainer />
              </ChatProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/add-new-user"
          element={
            <ProtectedRoute allowedRoles={[roles.Admin]}>
              <AddMemberDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/case"
          element={
            <ProtectedRoute
              allowedRoles={[
                roles.Admin,
                roles.Pathologist,
                roles.Technologist,
              ]}
            >
              <CaseInfoScreen />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/datagrid"
          element={
            <ProtectedRoute
              allowedRoles={[
                roles.Admin,
                roles.Pathologist,
                roles.Technologist,
              ]}
            >
              <DatagridTest />
            </ProtectedRoute>
          }
        />

        <Route index element={<DashboardRedirect />} />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route
        path="/chats"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Pathologist, roles.Technologist]}
          >
            <ChatProvider>
              <Chat />
            </ChatProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path="/dashboard/technologist"
        element={
          <ProtectedRoute allowedRoles={[roles.Admin, roles.Technologist]}>
            {/* <AppContainer /> */}
            <ChatProvider>
              <AppContainer />
            </ChatProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path="/create-case"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Technologist, roles.Admin, roles.Pathologist]}
          >
            <CreateCase />
          </ProtectedRoute>
        }
      />

      <Route
        path="/viewer"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Pathologist, roles.Technologist, roles.Admin]}
          >
            <ViewerRedirect />
          </ProtectedRoute>
        }
      />

      <Route
        path="/viewer/:caseName"
        element={
          <ProtectedRoute
            allowedRoles={[roles.Pathologist, roles.Technologist, roles.Admin]}
          >
            <OSDViewer />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouterConfig;
