import React, { useEffect, useState } from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Link, Flex } from "@chakra-ui/react";
import { useLazyGetSynopticReportQuery } from "../../state/API/HospitalApi";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "1.5vh 0vw",
    width: "100%",
  },
  tableBox: {
    minWidth: "400px",
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    padding: "0.5vh 2.5vw",
  },
  tableColumn: {
    minWidth: "33%",
    maxWidth: "33%",
    fontSize: "12px",
    height: "35px",
  },
  tableData: {
    display: "flex",
    flexDirection: "column",
  },
  tableDataLabel: {
    paddingLeft: "0.5208vw",
    paddingRight: "10px",
    marginTop: "5px",
  },
  tableDataLabelInfo: {
    padding: "0.7vh 0.5208vw",
    color: "#8F8F8F",
  },
  reportBox: {
    marginTop: "1.7vh",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0vh 2.5vw",
  },
  reportHeadline: {
    fontWeight: 600,
    fontSize: "12px",
  },
  title: {
    fontWeight: 600,
    fontSize: "12px",
    paddingBottom: "1vh",
    paddingTop: "1vh",
    paddingLeft: "1vw",
    width: "100%",
    backgroundColor: "#F7FBFD",
    margin: "0.6vh 0vw",
  },
  reportColumn: {
    minWidth: "45%",
    paddingTop: "12px",
    display: "flex",
    flexDirection: "column",
  },
  reportData: {
    fontWeight: "200",
    fontSize: "11px",
    paddingTop: "4px",
    color: "#8F8F8F",
    minHeight: "15px",
  },
  media: {
    width: "100%",
    paddingTop: "2vh",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  image: {
    width: "50px",
    height: "50px",
  },
  reporterBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    padding: "0vh 2.5vw",
  },
  reporterName: {
    color: "#3B5D7C",
    fontSize: "12px",
  },
  reporterDetails: {
    fontSize: "12px",
    paddingTop: "6px",
  },
  header: {
    height: "6vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "1vh 0vw",
    marginTop: "-1.5vh",
    marginLeft: "2vw",
    backgroundColor: "#F7FBFD",
    borderBottom: "1px solid #8F8F8F",
    width: "100%",
    fontSize: "14px",
  },
});

const Report = ({ caseInfo, userInfo, response }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Breast Cancer Synoptic Report</Text>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>PATIENT NAME</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.patientName?.firstName}`}
                {`${caseInfo?.patient?.patientName?.lastName}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>MRN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.patient?.uhid}`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>AGE</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.age?.years
                    ? caseInfo?.patient?.age?.years
                    : ""
                }`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.tableBox}>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>GENDER</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${
                  caseInfo?.patient?.gender ? caseInfo?.patient?.gender : ""
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.tableColumn}>
            <View style={styles.tableData}>
              <Text style={styles.tableDataLabel}>CLINICIAN</Text>
              <Text style={styles.tableDataLabelInfo}>
                {`${caseInfo?.treatingDoctor}`}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.reportBox}>
          <Text style={styles.title}>MACROSCOPY</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DATA RECEIVED</Text>
            <Text style={styles.reportData}>{response?.dataRecieved}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SPECIMEN TYPE</Text>
            <Text style={styles.reportData}>{response?.specimenType}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              SPECIMEN RADIOGRAPHY PROVIDED
            </Text>
            <Text style={styles.reportData}>
              {response?.specimenRadiographProvided}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              RADIOLOGICAL ABNORMALITY SEEN
            </Text>
            <Text style={styles.reportData}>
              {response?.radiologyAbnormalitySeen}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>R GRADE</Text>
            <Text style={styles.reportData}>{response?.rGrade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>RADIOLOGICAL LESION</Text>
            <Text style={styles.reportData}>{response?.radiologyLesion}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SPECIMEN WEIGHT</Text>
            <Text style={styles.reportData}>{response?.specimenWeight}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ELLIPSE OF SKIN</Text>
            <Text style={styles.reportData}>{response?.ellipseOfSkin}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>NIPPLE</Text>
            <Text style={styles.reportData}>{response?.nipple}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              HISTOLOGICAL CLASIFICATION PRESENT
            </Text>
            <Text style={styles.reportData}>
              {response?.histologicalClassificationPresent}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>FIBROFATTY TISSUE</Text>
            <Text style={styles.reportData}>{response?.fibrofattyTissue}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LESION MEASURES</Text>
            <Text style={styles.reportData}>{response?.lesionMeasures}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>SITE</Text>
            <Text style={styles.reportData}>{response?.site}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              MACROSCOPIC DISTANCE TO NEAREST MARGIN
            </Text>
            <Text style={styles.reportData}>
              {response?.macroscopicDistance}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>COMMENTS</Text>
            <Text style={styles.reportData}>{response?.comments}</Text>
          </View>
          <Text style={styles.title}>INVASIVE CARCINOMA</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>INVASIVE TUMOUR SIZE</Text>
            <Text style={styles.reportData}>
              {response?.invasiveTumourSize}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              WHOLE TUMOUR (DCIS + INVASIVE) SIZE MM
            </Text>
            <Text style={styles.reportData}>{response?.wholeTumourSize}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>GRADE</Text>
            <Text style={styles.reportData}>{response?.invasiveGrade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>TUMOUR EXTENT</Text>
            <Text style={styles.reportData}>{response?.tumourExtent}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>TYPE</Text>
            <Text style={styles.reportData}>{response?.type}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              SPECIFY TYPE COMPONENT(S) PRESENT FOR PURE SPECIAL TYPE AND MIXED
              TUMOUR TYPES:
            </Text>
            <Text style={styles.reportData}>{response?.typeForComponents}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>GRADE</Text>
            <Text style={styles.reportData}>{response?.grade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>ASSOCIATED DCIS</Text>
            <Text style={styles.reportData}>{response?.associatedDcis}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DCIS GRADE</Text>
            <Text style={styles.reportData}>{response?.dcisGrade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              IN SITU LOBULAR NEOPLASIA PRESENT
            </Text>
            <Text style={styles.reportData}>
              {response?.isSituLobularNeoplasia}
            </Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              PAGET&apos;S DISEASE PRESENT
            </Text>
            <Text style={styles.reportData}>{response?.isPagetDisease}</Text>
          </View>
          <Text style={styles.title}>FINAL PATHOLOGY DCIS</Text>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>
              PURE DCIS SIZE MM IN MAXIMUM EXTENT
            </Text>
            <Text style={styles.reportData}>{response?.pureDcisSize}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DCIS GRADE</Text>
            <Text style={styles.reportData}>{response?.pureDcisGrade}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DCIS ARCHITECTURE</Text>
            <Text style={styles.reportData}>{response?.dcisArchitecture}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>DCIS NERCROSIS</Text>
            <Text style={styles.reportData}>{response?.dcisNecrosis}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>LCIS PRESENT</Text>
            <Text style={styles.reportData}>{response?.isLcis}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>MICROINVASIVE</Text>
            <Text style={styles.reportData}>{response?.microInvasion}</Text>
          </View>
          <View style={styles.reportColumn}>
            <Text style={styles.reportHeadline}>PAGET&apos;S DISEASE</Text>
            <Text style={styles.reportData}>{response?.pagetDisease}</Text>
          </View>
        </View>
        <View style={styles.reporterBox}>
          <Text
            style={styles.reporterName}
          >{`Dr. ${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
          <Text style={styles.reporterDetails}>
            {userInfo?.highestDegree?.split("")?.join(".")?.toUpperCase()}
          </Text>
          <Text style={styles.reporterDetails}>{`${
            userInfo?.organization?.name
          }, ${
            userInfo?.organization?.address
              ? userInfo?.organization?.address
              : ""
          }`}</Text>
        </View>
      </Page>
    </Document>
  );
};

const BreastCancer = ({
  caseInfo,
  slideInfo,
  userInfo,
  fetchSynopticReport,
  setReportLinkData,
}) => {
  const [fetchReportData] = useLazyGetSynopticReportQuery();
  const [response, setResponse] = useState("");
  useEffect(() => {
    async function fetchReport() {
      if (caseInfo?._id) {
        const resp = await fetchReportData({
          reportType: "breast-cancer",
          caseId: caseInfo?._id,
        });
        setResponse(resp?.data);
      }
    }
    fetchReport();
  }, [fetchSynopticReport]);

  console.log(response);

  return (
    <Flex direction="column" w="100%" h="100%" alignItems="center">
      <Flex
        overflowY="scroll"
        overFlowX="clip"
        h="90%"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        bgColor="#fff"
      >
        <Report
          caseInfo={caseInfo}
          slideInfo={slideInfo}
          userInfo={userInfo}
          response={response?.data}
        />
      </Flex>
      <PDFDownloadLink
        document={
          <Report
            caseInfo={caseInfo}
            slideInfo={slideInfo}
            userInfo={userInfo}
            response={response?.data}
          />
        }
        fileName="case_report_breast_cancer.pdf"
      >
        {/* {({ url }) => (
          <Link href={url} isExternal style={{ color: "#1B75BC" }}>
            Download breast cancer report
          </Link>
        )} */}
        {({ url }) => setReportLinkData(url)}
      </PDFDownloadLink>
    </Flex>
  );
};

export default BreastCancer;
