import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import Sidebar from "../Sidebar";
import MemberDetails from "./MemberDetails";

const AddMemberDashboard = () => {
  return (
    <Flex w="100%">
      <Sidebar home={false} />
      <Flex w="100%" flexDirection="column">
        <Flex minWidth="768px">
          <MemberDetails />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddMemberDashboard;
