import React, { useEffect, useState } from "react";
import {
  Flex,
  VStack,
  useMediaQuery,
  Link,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import { BsChatText, BsChatTextFill } from "react-icons/bs";
import { IoDocumentTextOutline, IoDocumentText } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUserInfoQuery } from "../state/API/HospitalApi";

const Sidebar = ({ home, documents, chats }) => {
  const navigate = useNavigate();
  const [overDocuments, setOverDocuments] = useState(documents);
  const [overHome, setOverHome] = useState(home);
  const [overChats, setOverChats] = useState(chats);
  const [overStorage, setOverStorage] = useState(false);
  const [hoverHome, setHoverHome] = useState(false);
  const [hoverDocuments, setHoverDocuments] = useState(false);
  const [hoverWorkspace, setHoverWorkspace] = useState(false);
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  // console.log(overHome, "home");
  // console.log(overDocuments, "doc");
  // useEffect(() => {
  //   if (localStorage.getItem("page") === "cases") {
  //     setOverHome(false);
  //     setOverDocuments(true);
  //   } else if (localStorage.getItem("page") === "dashboard") {
  //     setOverHome(true);
  //     setOverDocuments(false);
  //   }
  // }, []);
  const { user } = useAuth0();
  const { data: userInfo, isLoading } = useGetUserInfoQuery({
    subClaim: user?.sub,
  });
  // console.log(userInfo.userType);

  const handleUserNavigation = () => {
    if (userInfo?.userType === "technologist") {
      navigate("/dashboard/technologist");
    } else {
      navigate("/");
    }
  };
  return (
    <Flex
      background="light.800"
      w="48px"
      h={ifWidthLessthan1920 ? "calc(100vh - 44px)" : "95.1852vh"}
      boxShadow="0px 0px 2px 2px rgba(176, 200, 214, 0.1)"
      borderRight="1px solid rgba(176, 200, 214, 0.15)"
      justifyContent="center"
    >
      <VStack mt="2vh" spacing="4vh">
        {overHome === false || undefined ? (
          // <Link as={RouteLink} to="/" _focus={{ outline: "none" }}>
          <AiOutlineHome
            size={20}
            // onMouseEnter={() => setHoverHome(true)}
            // onMouseLeave={() => setHoverHome(false)}
            onClick={() => {
              handleUserNavigation();
              localStorage.removeItem("caseNum");

              // setOverStorage(false);
              // setOverDocuments(false);
              // setOverHome(true);
              // setHoverDocuments(false);
              // setHoverWorkspace(false);
            }}
            style={{ cursor: "pointer" }}
          />
        ) : (
          // </Link>
          <Box borderLeft="2px solid #3B5D7C" mt="9px">
            <AiFillHome
              size={20}
              // onMouseEnter={() => setHoverHome(true)}
              // onMouseLeave={() => setHoverHome(false)}
              onClick={() => {
                navigate("/");
                localStorage.removeItem("caseNum");
                window.location.reload();

                // setOverStorage(false);
                // setOverDocuments(false);
                // setOverHome(true);
                // setHoverDocuments(false);
                // setHoverWorkspace(false);
              }}
              style={{
                cursor: "pointer",
                // borderLeft: "2px solid #3B5D7C",
                paddingLeft: "0px",
                marginTop: "8px",
                color: "#3B5D7C",
                marginLeft: "4px",
              }}
            />
          </Box>
        )}

        {userInfo?.userType === "technologist" ? (
          ""
        ) : userInfo?.userType !== "admin" ? (
          overDocuments === false || undefined ? (
            // <Link
            //   as={RouteLink}
            //   to="/dashboard/cases"
            //   _focus={{ outline: "none" }}
            // >
            <IoDocumentTextOutline
              size={20}
              onMouseEnter={() => setHoverDocuments(true)}
              onMouseLeave={() => setHoverDocuments(false)}
              onClick={() => {
                navigate("/dashboard/cases");
                // setOverStorage(false);
                // setOverDocuments(true);
                // setOverHome(false);
                // setHoverDocuments(false);
                // setHoverWorkspace(false);
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            // </Link>
            <IoDocumentText
              size={20}
              onMouseEnter={() => setHoverDocuments(true)}
              onMouseLeave={() => setHoverDocuments(false)}
              onClick={() => {
                navigate("/dashboard/cases");
                // setOverStorage(false);
                // setOverDocuments(true);
                // setOverHome(false);
                // setHoverWorkspace(false);
              }}
              style={{
                cursor: "pointer",
                // borderLeft: "2px solid #3B5D7C",
                paddingLeft: "0px",
                color: "#3B5D7C",
              }}
            />
          )
        ) : (
          ""
        )}
        {userInfo?.userType !== "admin" ? (
          overChats === false || undefined ? (
            // <Link
            //   as={RouteLink}
            //   to="/dashboard/cases"
            //   _focus={{ outline: "none" }}
            // >
            <BsChatText
              size={19}
              onMouseEnter={() => setHoverDocuments(true)}
              onMouseLeave={() => setHoverDocuments(false)}
              onClick={() => {
                navigate("/chats");
                // setOverStorage(false);
                // setOverDocuments(true);
                // setOverHome(false);
                // setHoverDocuments(false);
                // setHoverWorkspace(false);
              }}
              style={{ cursor: "pointer" }}
            />
          ) : (
            // </Link>
            <BsChatTextFill
              size={19}
              onMouseEnter={() => setHoverDocuments(true)}
              onMouseLeave={() => setHoverDocuments(false)}
              onClick={() => {
                navigate("/chats");
                // setOverStorage(false);
                // setOverDocuments(true);
                // setOverHome(false);
                // setHoverWorkspace(false);
              }}
              style={{
                cursor: "pointer",
                // borderLeft: "2px solid #3B5D7C",
                paddingLeft: "0px",
                color: "#3B5D7C",
              }}
            />
          )
        ) : (
          ""
        )}
        {/* {overDocuments === false ? (
          <Image
            src={hoverDocuments ? DocumentIconFilled : DocumnentIcon}
            // onMouseEnter={() => setHoverDocuments(true)}
            // onMouseLeave={() => setHoverDocuments(false)}
            // onClick={() => {
            //   onOpen();
            //   setOverStorage(false);
            //   setOverDocuments(true);
            //   setOverHome(false);
            //   setHoverHome(false);
            //   setHoverWorkspace(false);
            // }}
            style={{ cursor: "not-allowed" }}
          />
        ) : (
          <Image
            src={DocumentIconFilled}
            borderLeft={hoverDocuments ? "2px solid #3B5D7C" : ""}
            paddingLeft={hoverDocuments ? "0.2vw" : ""}
            style={{ cursor: "not-allowed" }}
          />
        )}

        {overStorage === false ? (
          <Image
            src={hoverWorkspace ? WorkspaceIconFilled : WorkspaceIcon}
            // onMouseEnter={() => setHoverWorkspace(true)}
            // onMouseLeave={() => setHoverWorkspace(false)}
            // onClick={() => {
            //   onOpen();
            //   setOverStorage(true);
            //   setOverDocuments(false);
            //   setOverHome(false);
            //   setHoverHome(false);
            //   setHoverDocuments(false);
            // }}
            style={{ cursor: "not-allowed" }}
          />
        ) : (
          <Image
            src={WorkspaceIconFilled}
            borderLeft={hoverWorkspace ? "2px solid #3B5D7C" : ""}
            paddingLeft={hoverWorkspace ? "0.2vw" : ""}
            style={{ cursor: "not-allowed" }}
          />
        )} */}
      </VStack>
    </Flex>
  );
};

export default Sidebar;
