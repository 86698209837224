import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  MenuItemOption,
  Text,
} from "@chakra-ui/react";
import React from "react";
import BreastCancer from "./BreastCancer";
import Lymphoma from "./Lymphoma";
import ProstateCancer from "./ProstateCancer";

const SynopticReport = ({ caseInfo, slideInfo, userInfo, fetchReport }) => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton
          _focus={{ outline: "none" }}
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #DEDEDE"
          _hover={{ bg: "none" }}
        >
          <Text fontSize="14px">Synoptic Report</Text>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4} px="0">
          <MenuItemOption minH="32px" _hover={{ bg: "none" }}>
            <BreastCancer
              caseInfo={caseInfo}
              slideInfo={slideInfo}
              userInfo={userInfo}
              fetchSynopticReport={fetchReport}
            />
          </MenuItemOption>
          <MenuItemOption minH="32px" _hover={{ bg: "none" }}>
            <ProstateCancer
              caseInfo={caseInfo}
              slideInfo={slideInfo}
              userInfo={userInfo}
              fetchSynopticReport={fetchReport}
            />
          </MenuItemOption>
          <MenuItemOption minH="32px" _hover={{ bg: "none" }}>
            <Lymphoma
              caseInfo={caseInfo}
              slideInfo={slideInfo}
              userInfo={userInfo}
              fetchSynopticReport={fetchReport}
            />
          </MenuItemOption>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default SynopticReport;
