import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Loading from "../Components/Loading/loading";
import { useGetUserInfoQuery } from "../state/API/HospitalApi";
import { PageNotFound, ServerDown } from "../Components/ErrorPages";

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const {
    data: userInfo,
    error,
    isLoading,
  } = useGetUserInfoQuery(
    {},
    { skip: !isAuthenticated, refetchOnMountOrArgChange: true }
  );

  if (isAuthLoading || isLoading) return <Loading />;

  if (!isAuthenticated) return <Navigate to="/login" />;

  if (userInfo && allowedRoles.includes(userInfo.userType)) return children;

  if (error && error.status === 404) return <Navigate to="/registration" />;

  if (error && (error.status === "FETCH_ERROR" || error.status >= 500))
    return <ServerDown />;

  return <PageNotFound />;
};

export default ProtectedRoute;
