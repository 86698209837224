import React, { useState, useEffect } from "react";
import {
  Flex,
  Menu,
  MenuList,
  MenuItem,
  Button,
  MenuButton,
  Text,
  Link,
  Image,
} from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";
import ReportDocument from "../../Case/Report";
import BreastCancer from "../SynopticReport/BreastCancer";
import Lymphoma from "../SynopticReport/Lymphoma";
import ProstateCancer from "../SynopticReport/ProstateCancer";
import reportDownloadLogo from "../../Static/Images/reportDownloadLogo.svg";

const ReportTab = ({ caseInfo, slideInfo, userInfo, report }) => {
  // localStorage.setItem("tab", "4");
  const [reportLinkData, setReportLinkData] = useState("");
  const [fetchReport, setFetchReport] = useState(false);
  const [reportType, setReportType] = useState("Standard");

  return (
    <Flex w="100%" minH="70vh" direction="column" bgColor="light.200">
      {caseInfo?.isReported || caseInfo?.isSynopticReported ? (
        <Flex w="100%" direction="column">
          <Flex w="100%" justifyContent="flex-end" p="10px 0">
            <Link href={reportLinkData} isExternal style={{ color: "#000000" }}>
              {" "}
              <Button
                fontSize="14px"
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="#fff"
                mr="12px"
                leftIcon={<Image pb="3px" src={reportDownloadLogo} />}
              >
                Download
              </Button>
            </Link>
            <Flex />
            <Menu>
              <MenuButton
                as={Button}
                fontSize="14px"
                fontWeight="500"
                border="1px solid #E8E8EB"
                borderRadius="5px"
                bgColor="#fff"
                mr="12px"
                rightIcon={<AiFillCaretDown />}
              >{`Report type: ${reportType ?? report}`}</MenuButton>
              <MenuList>
                {caseInfo?.isReported ? (
                  <MenuItem onClick={() => setReportType("Standard")}>
                    Standard
                  </MenuItem>
                ) : null}

                {caseInfo?.isSynopticReported ? (
                  caseInfo?.organs[0].organName === "breast" ? (
                    <MenuItem onClick={() => setReportType("Breast cancer")}>
                      Breast cancer
                    </MenuItem>
                  ) : caseInfo?.organs[0].organName === "lymph-nodes" ? (
                    <MenuItem onClick={() => setReportType("Lymphoma")}>
                      Lymphoma
                    </MenuItem>
                  ) : caseInfo?.organs[0].organName === "prostate-gland" ? (
                    <MenuItem onClick={() => setReportType("Prostate cancer")}>
                      Prostate cancer
                    </MenuItem>
                  ) : null
                ) : null}
              </MenuList>
            </Menu>
          </Flex>
          <Flex>
            {reportType === "Standard" ? (
              <ReportDocument
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                setReportLinkData={setReportLinkData}
              />
            ) : reportType === "Breast cancer" ? (
              <BreastCancer
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                fetchSynopticReport={fetchReport}
                setReportLinkData={setReportLinkData}
              />
            ) : reportType === "Lymphoma" ? (
              <Lymphoma
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                fetchSynopticReport={fetchReport}
                setReportLinkData={setReportLinkData}
              />
            ) : reportType === "Prostate cancer" ? (
              <ProstateCancer
                caseInfo={caseInfo}
                slideInfo={slideInfo}
                userInfo={userInfo}
                fetchSynopticReport={fetchReport}
                setReportLinkData={setReportLinkData}
              />
            ) : null}
          </Flex>
        </Flex>
      ) : (
        <Flex
          bgColor="light.200"
          w="100%"
          minH="70vh"
          justifyContent="center"
          alignItems="center"
        >
          <Text opacity="0.7" fontSize="18px">
            Case has not been reported yet
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ReportTab;
