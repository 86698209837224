import {
  Box,
  Flex,
  Text,
  useMediaQuery,
  HStack,
  Switch,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuOptionGroup,
  MenuItemOption,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { AiFillCaretDown } from "react-icons/ai";
import Loading from "../Loading/loading";
import CaseTable from "./CasesTable";
import {
  useGetFilterListQuery,
  useGetSharedCasesQuery,
} from "../../state/API/HospitalApi";

const TotalCases = ({
  setCase,
  userInfo,
  setDoctorList,
  setOrganList,
  filterData,
  isLoading,
  searchResponse,
  searchInput,
  sharedCaseData,
}) => {
  const [ifWidthLessthan1600] = useMediaQuery("(max-width:1600px)");
  const [isUrgent, setIsUrgent] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const menuRef = useRef(null);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef?.current && !menuRef?.current?.contains(event.target)) {
        onClose();
      }
    }

    function handleMouseEnter() {
      setIsOpen(true);
    }

    function handleMouseLeave() {
      setIsOpen(false);
    }

    document?.addEventListener("mousedown", handleClickOutside);
    menuRef?.current?.addEventListener("mouseenter", handleMouseEnter);
    menuRef?.current?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
      menuRef?.current?.removeEventListener("mouseenter", handleMouseEnter);
      menuRef?.current?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [onClose]);

  const [isOpen1, setIsOpen1] = useState(false);
  const menuRef1 = useRef(null);

  const onClose1 = useCallback(() => {
    setIsOpen1(false);
  }, []);

  useEffect(() => {
    function handleClickOutside1(event) {
      if (menuRef1?.current && !menuRef1?.current?.contains(event.target)) {
        onClose1();
      }
    }

    function handleMouseEnter1() {
      setIsOpen1(true);
    }

    function handleMouseLeave1() {
      setIsOpen1(false);
    }

    document.addEventListener("mousedown", handleClickOutside1);
    menuRef1?.current?.addEventListener("mouseenter", handleMouseEnter1);
    menuRef1?.current?.addEventListener("mouseleave", handleMouseLeave1);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
      menuRef1?.current?.removeEventListener("mouseenter", handleMouseEnter1);
      menuRef1?.current?.removeEventListener("mouseleave", handleMouseLeave1);
    };
  }, [onClose1]);

  // const { data: filterList } = useGetFilterListQuery({
  //   organizationId: userInfo?.organization?._id,
  // });
  // const { data: sharedCaseData } = useGetSharedCasesQuery({
  //   userId: userInfo?._id,
  // });

  const data =
    searchInput?.length > 0
      ? searchResponse?.map((x) => ({
          date: moment(x.caseCreationDate).format("DD/MM/YYYY"),
          caseNumber: x.caseId,
          organ: x.organs[0].organName,
          department: x.departmentFrom,
          patientName: `${x?.patientDetailsWithOutPipeline[0]?.patientName?.firstName} ${x?.patientDetailsWithOutPipeline[0]?.patientName?.lastName}`,
          uhid: x?.patientDetailsWithOutPipeline[0]?.uhid,
          slides: x.slides.length,
          id: x._id,
          status: x.caseStatus,
          isReported: x.isReported,
          isSynopticReported: x.isSynopticReported,
          createdOn: x.caseCreationDate,
          clinician: x.treatingDoctor,
          caseUsers: x.caseUsers,
          responseType: "input",
          hospitalName: x?.treatingHospitalDetails?.hospitalName,
        }))
      : index === 0
      ? filterData?.map((x) => ({
          date: moment(x.caseCreationDate).format("DD/MM/YYYY"),
          caseNumber: x.caseId,
          organ: x.organs[0].organName,
          department: x.departmentFrom,
          patientName: `${x?.patient?.patientName?.firstName}`,
          uhid: x?.patient?.uhid,
          slides: x.slides.length,
          id: x._id,
          status: x.caseStatus,
          isReported: x.isReported,
          isSynopticReported: x.isSynopticReported,
          createdOn: x.caseCreationDate,
          clinician: x.treatingDoctor,
          caseUsers: x.caseUsers,
          responseType: "filtered",
          hospitalName: x?.treatingHospitalDetails?.hospitalName,
        }))
      : sharedCaseData?.map((x) => ({
          date: moment(x.caseCreationDate).format("DD/MM/YYYY"),
          caseNumber: x.caseId,
          organ: x.organs[0].organName,
          department: x.departmentFrom,
          patientName: `${x?.patient?.patientName?.firstName}`,
          uhid: x?.patient?.uhid,
          slides: x.slides.length,
          id: `${x._id}-shared`,
          status: x.caseStatus,
          isReported: x.isReported,
          isSynopticReported: x.isSynopticReported,
          createdOn: x.caseCreationDate,
          clinician: x.treatingDoctor,
          caseUsers: x.caseUsers,
          responseType: "filtered",
          hospitalName: x?.treatingHospitalDetails?.hospitalName,
        }));
  return isLoading ? (
    <Loading />
  ) : (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      border="1px solid #E8E8EB"
      borderRadius="10px"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        p={ifWidthLessthan1600 ? "12px 12px 16px 0" : "16px 16px 16px 0"}
        h={ifWidthLessthan1600 ? "55px" : "70px"}
      >
        <HStack w="300px" spacing={0}>
          <Flex w="130px">
            <Text
              fontSize={ifWidthLessthan1600 ? "14px" : "21px"}
              fontWeight={600}
              cursor="pointer"
              color={index === 0 ? "light.1006" : "#000"}
              borderBottom={index === 0 && "3px solid #1B75BC"}
              w="100%"
              textAlign="center"
              onClick={() => setIndex(0)}
            >
              My Cases
            </Text>
          </Flex>
          <Flex w="150px">
            <Text
              fontSize={ifWidthLessthan1600 ? "14px" : "21px"}
              fontWeight={600}
              cursor="pointer"
              color={index === 1 && "light.1006"}
              borderBottom={index === 1 && "3px solid #1B75BC"}
              w="100%"
              textAlign="center"
              onClick={() => setIndex(1)}
            >
              Shared Cases
            </Text>
          </Flex>
        </HStack>
      </HStack>

      <CaseTable filterData={data} isUrgent={isUrgent} />
    </Flex>
  );
};

export default TotalCases;
