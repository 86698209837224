import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  VStack,
  useMediaQuery,
  HStack,
  Button,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import moment from "moment";
import axios from "axios";
import Environment from "../../../environment";
import ScrollBar from "../../other/Scrollbar";

const Overview = ({ caseInfo }) => {
  const toast = useToast();
  const [isEditActive, setIsEditActive] = useState(false);
  const [inputData, setInputData] = useState({
    caseId: "",
    organ: "",
    department: "",
    hospital: "",
    clinician: "",
    specimenSize: "",
  });

  useEffect(() => {
    if (caseInfo) {
      setInputData({
        caseId: caseInfo?.caseId,
        organ: caseInfo?.organs[0].organName,
        department: caseInfo?.departmentFrom,
        hospital: caseInfo?.treatingHospitalDetails.hospitalName,
        clinician: caseInfo?.treatingDoctor,
        specimenSize: caseInfo?.organs[1].organSize,
      });
    }
  }, caseInfo);
  const handleInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async () => {
    const resp = await axios.put(
      `${Environment.USER_URL}/edit_case_info?_id=${caseInfo?._id}`,
      {
        organs: [
          { organName: inputData.organ },
          { organSize: inputData.specimenSize },
        ],
        treatingHospitalDetails: { hospitalName: inputData.hospital },
        treatingDoctor: inputData.clinician,
        caseId: inputData.caseId,
        departmentFrom: inputData.department,
      }
    );
    if (resp.status === 200) {
      toast({
        title: "Case details updated",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => window.location.reload(), 1500);
    } else {
      toast({
        title: "Some error occured",
        description: "Please try again after some time",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");
  const headerStyle = {
    fontSize: ifWidthLessthan1920 ? "11px" : "13px",
    opacity: "0.7",
    fontFamily: "Inter",
  };
  const infoStyle = {
    fontSize: ifWidthLessthan1920 ? "12px" : "18px",
    fontFamily: "Inter",
  };
  return isEditActive ? (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
      pos="relative"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Case overview
        </Text>
      </HStack>

      <ScrollBar>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          // justifyContent="flex-start"
          alignItems="flex-end"
          spacing={ifWidthLessthan1920 ? "8px" : "15px"}
          mt="10px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Case Id</Text>
              <Input
                defaultValue={inputData.caseId}
                variant="flushed"
                h="30px"
                name="caseId"
                onChange={handleInput}
              />
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="10px">
              <Text style={headerStyle}>Organ</Text>
              <Select
                id="organ"
                name="organ"
                borderRadius={5}
                h="30px"
                value={inputData.organ}
                onChange={(e) => handleInput(e)}
              >
                <option hidden disabled value="">
                  Select option
                </option>
                <option value="brain">Brain</option>
                <option value="spinal-cord">Spinal cord</option>
                <option value="nerves">Nerves</option>
                <option value="heart">Heart</option>
                <option value="blood-vessels">Blood vessels</option>
                <option value="lungs">Lungs</option>
                <option value="trachea">Trachea</option>
                <option value="bronchi">Bronchi</option>
                <option value="diaphragm">Diaphragm</option>
                <option value="liver">Liver</option>
                <option value="gallbladder">Gallbladder</option>
                <option value="pancreas">Pancreas</option>
                <option value="spleen">Spleen</option>
                <option value="stomach">Stomach</option>
                <option value="small-intestine">Small intestine</option>
                <option value="large-intestine">Large intestine</option>
                <option value="rectum">Rectum</option>
                <option value="anus">Anus</option>
                <option value="kidneys">Kidneys</option>
                <option value="ureters">Ureters</option>
                <option value="bladder">Bladder</option>
                <option value="urethra">Urethra</option>
                <option value="adrenal-glands">Adrenal glands</option>
                <option value="thyroid-gland">Thyroid gland</option>
                <option value="parathyroid-glands">Parathyroid glands</option>
                <option value="pituitary-gland">Pituitary gland</option>
                <option value="pineal-gland">Pineal gland</option>
                <option value="thymus-gland">Thymus gland</option>
                <option value="ovaries">Ovaries</option>
                <option value="uterus">Uterus</option>
                <option value="fallopian-tubes">Fallopian tubes</option>
                <option value="vagina">Vagina</option>
                <option value="testes">Testes</option>
                <option value="penis">Penis</option>
                <option value="seminal-vesicles">Seminal vesicles</option>
                <option value="prostate-gland">Prostate gland</option>
                <option value="skin">Skin</option>
                <option value="hair">Hair</option>
                <option value="nails">Nails</option>
                <option value="muscles">Muscles</option>
                <option value="bones">Bones</option>
                <option value="joints">Joints</option>
                <option value="cartilage">Cartilage</option>
                <option value="tendons">Tendons</option>
                <option value="ligaments">Ligaments</option>
                <option value="eyes">Eyes</option>
                <option value="ears">Ears</option>
                <option value="nose">Nose</option>
                <option value="tongue">Tongue</option>
                <option value="teeth">Teeth</option>
                <option value="salivary-glands">Salivary glands</option>
                <option value="sweat-glands">Sweat glands</option>
                <option value="mammary-glands">
                  Mammary glands (in females)
                </option>
                <option value="adipose-tissue">Fat (adipose tissue)</option>
                <option value="lymph-nodes">Lymph nodes</option>
                <option value="lymphatic-vessels">Lymphatic vessels</option>
                <option value="thymus">Thymus</option>
                <option value="breast">Breast</option>
              </Select>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Department</Text>
              <Select
                id="department"
                name="department"
                borderRadius={5}
                h="30px"
                value={inputData.department}
                onChange={(e) => handleInput(e)}
              >
                <option hidden disabled value="">
                  Select option
                </option>
                <option value="cytopathology">Cytopathology</option>
                <option value="hematopathology">Hematopathology</option>
                <option value="histopathology">Histopathology</option>
              </Select>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="10px">
              <Text style={headerStyle}>Hospital</Text>
              <Input
                defaultValue={inputData.hospital}
                variant="flushed"
                h="30px"
                name="hospital"
                onChange={handleInput}
              />
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Clinician</Text>
              <Input
                defaultValue={inputData.clinician}
                variant="flushed"
                h="30px"
                name="clinician"
                onChange={handleInput}
              />
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="10px">
              <Text style={headerStyle}>Specimen size</Text>
              <Select
                id="specimen_size"
                name="specimenSize"
                borderRadius={5}
                h="30px"
                value={inputData.specimenSize}
                onChange={(e) => handleInput(e)}
              >
                <option hidden disabled value="">
                  Select option
                </option>
                <option value="large">Large</option>
                <option value="medium">Medium</option>
                <option value="small">Small</option>
              </Select>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Created</Text>
              <Input
                defaultValue={moment(caseInfo?.caseCreationDate).format(
                  " DD/MM/YYYY HH:mm A"
                )}
                variant="flushed"
                h="30px"
                name="createdAt"
                onChange={handleInput}
                disabled
              />
            </VStack>
          </HStack>
          <HStack pos="absolute" bottom="5px">
            <Button
              onClick={() => setIsEditActive(false)}
              color="#1B75BC"
              bgColor="inherit"
              fontSize="14px"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              bgColor="#C6DDEE"
              color="#1B75BC"
              fontSize="14px"
              w="100px"
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </ScrollBar>
    </Flex>
  ) : (
    <Flex
      h="36vh"
      minH="300px"
      w="100%"
      bgColor="#fff"
      borderRadius="10px"
      p="1vw"
      direction="column"
    >
      <HStack w="100%" justifyContent="space-between">
        <Text
          color="#1B75BC"
          fontSize={ifWidthLessthan1920 ? "14px" : "20px"}
          fontWeight="600"
        >
          Case overview
        </Text>
        <Button bgColor="inherit" onClick={() => setIsEditActive(true)}>
          <HiOutlinePencilAlt color="#1B75BC" />
        </Button>
      </HStack>

      <ScrollBar>
        <VStack
          w="100%"
          wordBreak="break-all"
          direction="column"
          justifyContent="flex-start"
          spacing={ifWidthLessthan1920 ? "8px" : "15px"}
          mt="10px"
        >
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Case Id</Text>
              <Text style={infoStyle}>{caseInfo?.caseId}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Organ</Text>
              <Text style={infoStyle}>{caseInfo?.organs[0].organName}</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Department</Text>
              <Text style={infoStyle}>{caseInfo?.departmentFrom}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Hospital</Text>
              <Text style={infoStyle}>
                {caseInfo?.treatingHospitalDetails.hospitalName}
              </Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="50%">
              <Text style={headerStyle}>Clinician</Text>
              <Text style={infoStyle}>{caseInfo?.treatingDoctor}</Text>
            </VStack>
            <VStack alignItems="left" spacing={0} w="50%" pl="30px">
              <Text style={headerStyle}>Specimen size</Text>
              <Text style={infoStyle}>{caseInfo?.organs[1].organSize}</Text>
            </VStack>
          </HStack>
          <HStack w="100%" alignItems="flex-start">
            <VStack alignItems="left" spacing={0} w="100%">
              <Text style={headerStyle}>Created</Text>
              <Text style={infoStyle}>
                {moment(caseInfo?.caseCreationDate).format(
                  " DD/MM/YYYY HH:mm A"
                )}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </ScrollBar>
    </Flex>
  );
};

export default Overview;
