/* eslint-disable react/no-children-prop */
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  useBoolean,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  HStack,
  Text,
  Stack,
  Box,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { FiFilter, FiChevronUp, FiChevronDown } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import { BsPlusLg, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { useState, useEffect } from "react";
import ViewerPermission from "./ViewerPermission";

const MembersList = ({
  orgDetails,
  setSearchUser,
  searchUser,
  ...restProps
}) => {
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [flag, setFlag] = useBoolean();
  const [userTypeList, setUserTypeList] = useState([
    "pathologist",
    "technologist",
  ]);
  const [TilHover, setTilHover] = useState(false);
  const [buttonOff, setButtonOff] = useState(true);
  const [buttonOffNext, setButtonOffNext] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffSet] = useState(0);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [number, setNumber] = useState(0);
  const [list, setList] = useState(currentItems);
  const [radioButton, setRadioButton] = useState("All");
  const data = orgDetails?.users === undefined ? [] : orgDetails.users;
  const itemsPerPage = 15;
  let srNo = 0;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    if (
      (radioButton === "Technologist" || radioButton === "Pathologist") &&
      pageCount * itemsPerPage < endOffset
    ) {
      setItemOffSet(0);
      setButtonOff(true);
    }
    if (endOffset === pageCount * itemsPerPage) {
      setButtonOffNext(true);
    } else {
      setButtonOffNext(false);
    }

    setCurrentItems(
      [...data]
        .reverse()
        .filter((userData) => {
          if (
            userData.userType === radioButton.toLowerCase() ||
            radioButton === "All"
          ) {
            return userData;
          }
          return null;
        })
        .slice(itemOffset, endOffset)
    );
    setPageCount(
      Math.ceil(
        [...data].filter((userData) => {
          if (
            userData.userType === radioButton.toLowerCase() ||
            radioButton === "All"
          ) {
            return userData;
          }
          return null;
        }).length / itemsPerPage
      )
    );
    setNumber(itemOffset);
    // console.log(pageCount);
  }, [itemOffset, itemsPerPage, data, radioButton, pageCount]);

  useEffect(() => {
    if (searchUser === "") {
      setList(currentItems);
      setSearchEnabled(false);
    } else {
      setList(data);
      setSearchEnabled(true);
    }
  }, [searchUser, currentItems, searchEnabled, radioButton, pageCount]);

  const handlePageClick = (event) => {
    const newOffSet =
      ((event.selected > pageCount ? pageCount : event.selected) *
        itemsPerPage) %
      data.length;
    if (newOffSet === 0) {
      setButtonOff(true);
    } else {
      setButtonOff(false);
    }
    setItemOffSet(newOffSet);
  };

  return (
    <Flex direction="column" w="100%" pb="29px">
      <Flex
        w="100%"
        h={ifSmallScreen ? "calc(100vh - 84px)" : "calc(100vh - 9.06vh)"}
        bgColor="light.200"
        p="20px"
        direction="column"
        {...restProps}
      >
        <HStack w="100%" h="7%" mb="2%">
          <Flex w="100%">
            <Menu>
              <MenuButton
                w="138px"
                h="33px"
                _focus={{ outline: "none" }}
                borderRadius={0}
                bgColor="light.700"
                color="#fff"
                _hover={{ bgColor: "#3B5D7C" }}
                _active={{ bgColor: "#3B5D7C" }}
              >
                + Add Member
              </MenuButton>
              <MenuList
                borderRadius={0}
                p={0}
                minWidth="138px"
                css={{
                  transform: "translate3d(0px, -8px, 0px)!important;",
                  border: "1px solid black",
                }}
              >
                <MenuItem
                  onClick={() =>
                    navigate("/dashboard/add-new-user", {
                      state: { userType: "pathologist" },
                    })
                  }
                >
                  Pathologist
                </MenuItem>
                <Flex justifyContent="center" alignItems="center">
                  <Flex bgColor="black" width="94%" h="0.5px" />
                </Flex>
                <MenuItem
                  onClick={() =>
                    navigate("/dashboard/add-new-user", {
                      state: { userType: "technologist" },
                    })
                  }
                >
                  Technologist
                </MenuItem>
              </MenuList>
            </Menu>

            <InputGroup w="30%" ml="5%">
              <InputLeftElement h={8} w={5} marginLeft={3}>
                <IconButton
                  variant="link"
                  padding={0}
                  h={5}
                  w={5}
                  bgColor="white"
                  icon={<SearchIcon color="gray.300" />}
                  _hover={{ cursor: "auto" }}
                />
              </InputLeftElement>
              <Input
                maxLength={65}
                placeholder="Search Users"
                h={34.5}
                border="2px solid #3B5D7C"
                borderRadius={2}
                bgColor="#fff"
                onChange={(e) => setSearchUser(e.target.value)}
                _focus={{
                  outline: "none",
                  border: "2px solid #3b5d7c",
                }}
                _hover={{
                  outline: "none",
                  border: "2px solid #3b5d7c",
                }}
                // outline={TilHover ? "1px solid rgba(0, 21, 63, 1)" : ""}
                onClick={() => {
                  setTilHover(!TilHover);
                }}
              />
            </InputGroup>
          </Flex>

          <Menu closeOnSelect={false} placement="bottom" pr={0}>
            <MenuButton
              as={Button}
              onClick={setFlag.toggle}
              _focus={{ outline: "none" }}
              _active={{ bgColor: "light.1000" }}
              border="2px solid #000"
              borderRadius={0}
              background="light.1000"
              fontWeight={300}
              _hover={{ background: "light.1000" }}
              minWidth="141px"
              fontSize="14px"
              rightIcon={
                flag ? <FiChevronUp size={18} /> : <FiChevronDown size={18} />
              }
              px={1.5}
              py={1.5}
            >
              <HStack>
                <FiFilter size={18} />
                <Text>Filter</Text>
              </HStack>
            </MenuButton>
            <MenuList
              // border="1px solid black"
              borderRadius={0}
              minWidth="141px"
              overflowY="scroll"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
                transform: "translate3d(0px, -8px, 0px)!important;",
                borderTopStyle: "none",
              }}
              pl={1.5}
            >
              <RadioGroup
                defaultValue="All"
                onChange={(e) => {
                  setRadioButton(e);
                }}
              >
                <Stack>
                  <Radio value="All">All</Radio>
                  <Flex bgColor="black" width="100%" h="0.5px" />
                  <Radio value="Pathologist">Pathologist</Radio>
                  <Flex bgColor="black" width="100%" h="0.5px" />
                  <Radio value="Technologist">Technologist</Radio>
                </Stack>
              </RadioGroup>
            </MenuList>
          </Menu>
        </HStack>

        <Flex
          w="100%"
          bgColor="light.800"
          minH="50px"
          alignItems="center"
          ps="2%"
          fontSize="14px"
          fontWeight={600}
        >
          <Flex minW="7%">Sr No.</Flex>
          <Flex minW="20%">Name</Flex>
          <Flex minW="13%">Role</Flex>
          <Flex minW="15%">Enroll date</Flex>
          <Flex minW="20%">Email</Flex>
          <Flex minW="13%">Contact</Flex>
          <Flex minW="13%">Permissions</Flex>
        </Flex>
        <Flex
          direction="column"
          overflow="scroll"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
            },
          }}
        >
          {list
            ?.filter((user) => {
              if (searchUser === "") {
                return user;
              }
              if (
                user?.firstName
                  ?.toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.lastName
                  .toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.emailAddress
                  .toLowerCase()
                  .includes(searchUser.toLowerCase()) ||
                user?.phoneNumber
                  ?.toString()
                  .indexOf(searchUser?.toString()) !== -1
              ) {
                return user;
              }
              return null;
            })
            .filter((userData) => {
              // console.log(userData.userType);
              if (
                userData.userType === radioButton.toLowerCase() ||
                radioButton === "All"
              ) {
                return userData;
              }
              return null;
            })
            .map((userInfo) => {
              if (userInfo?.userType !== "admin") srNo += 1;
              return userInfo?.userType !== "admin" &&
                userTypeList.includes(userInfo?.userType) ? (
                <Flex direction="column" w="100%">
                  <Flex
                    w="100%"
                    minH="48px"
                    alignItems="center"
                    ps="2%"
                    fontSize="14px"
                    key={userInfo._id}
                  >
                    <Flex w="7%">
                      {number === 0 || searchEnabled === true
                        ? srNo
                        : srNo + number}
                    </Flex>
                    <Flex w="20%" wordBreak="break-word">
                      {userInfo.userType === "pathologist" && "Dr. "}
                      {userInfo.firstName.charAt(0).toUpperCase() +
                        userInfo.firstName.slice(1)}{" "}
                      {userInfo.lastName.charAt(0).toUpperCase() +
                        userInfo.lastName.slice(1)}
                    </Flex>
                    <Flex w="13%" wordBreak="break-word">
                      {userInfo.userType.charAt(0).toUpperCase() +
                        userInfo.userType.slice(1)}
                    </Flex>
                    <Flex w="15%" wordBreak="break-word">
                      {userInfo.enrollDate
                        ? userInfo.enrollDate.substring(0, 10)
                        : ""}
                    </Flex>
                    <Flex
                      w="20%"
                      wordBreak="break-word"
                      pr={ifSmallScreen ? "2%" : "0"}
                    >
                      {userInfo.emailAddress}
                    </Flex>
                    <Flex w="13%" wordBreak="break-word">
                      {userInfo.phoneNumber}
                    </Flex>
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      padding="8px 0px 8px 24px"
                    >
                      <IconButton
                        onClick={onOpen}
                        icon={<BsPlusLg />}
                        _focus={{ outline: "none" }}
                      />
                    </Flex>
                  </Flex>
                  <Box
                    width="100%"
                    bgColor="#AEAEAE"
                    height="0.2px"
                    marginBottom="2px"
                  />
                </Flex>
              ) : null;
            })}
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize="20px" fontWeight="500">
                Viewer Permissions
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody px="0" alignItems="center">
                <ViewerPermission onClose={onClose} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
      <Box
        css={
          searchUser === ""
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {radioButton === "Technologist" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
        {radioButton === "All" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
        {radioButton === "Pathologist" && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            forcePage={
              itemOffset + 1 > pageCount * itemsPerPage
                ? 0
                : itemOffset / itemsPerPage
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousLinkClassName={buttonOff ? "page-num-disable" : "page-num"}
            nextLinkClassName={
              buttonOffNext || pageCount === 1 ? "page-num-disable" : "page-num"
            }
            activeLinkClassName="active"
          />
        )}
      </Box>
    </Flex>
  );
};

export default MembersList;
