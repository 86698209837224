import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Table,
  Thead,
  Tbody,
  Flex,
  Tr,
  Th,
  Td,
  TableContainer,
  useMediaQuery,
  HStack,
  Text,
  AvatarGroup,
  Avatar,
  Image,
} from "@chakra-ui/react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { FaSort } from "react-icons/fa";
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { TbUrgent } from "react-icons/tb";
import { MdPendingActions } from "react-icons/md";
import NewCaseIcon from "../../Static/Images/NewIcon.png";

const CaseTable = ({ filterData, isUrgent }) => {
  const navigate = useNavigate();
  const [isLargerThanSm] = useMediaQuery("(min-width: 1280px)");
  const [isLargerThanMd] = useMediaQuery("(min-width: 1290px)");
  const [isLargerThanLg] = useMediaQuery("(min-width: 1880px)");
  const [isLargerThanXl] = useMediaQuery("(min-height: 1980px)");
  // default value
  const getComponentSize = () => {
    let size = "56vh";
    switch (true) {
      case isLargerThanXl:
        size = "65vh";
        break;
      case isLargerThanLg:
        size = "64vh";
        break;
      case isLargerThanMd:
        size = "60vh";
        break;
      case isLargerThanSm:
        size = "56vh";
        break;
      default:
        size = "56vh";
        break;
    }
    return size;
  };

  const componentSize = getComponentSize();
  const columns = [
    {
      field: "status",
      headerName: "",
      width: 50,
      editable: true,
      renderCell: (params) =>
        // console.log(params.row.status, params.row.isSynopticReported),
        params.row.isReported || params.row.isSynopticReported ? (
          <AiOutlineCheckCircle color="#3F62CB" size={20} />
        ) : params.row.status === "urgent" ? (
          <TbUrgent color="#D06768" size={20} />
        ) : moment.duration(moment().diff(params.row.date)).asDays() > 2 ? (
          <MdPendingActions color="#FF9900" size={20} />
        ) : (
          <Image src={NewCaseIcon} w="20px" />
        ),
    },
    {
      field: "caseNumber",
      headerName: "Case ID",
      width: 200,
      editable: true,
      // valueGetter: (params) => `${params.row.date || ""}`,
    },
    {
      field: "date",
      headerName: "Date Created",
      width: 175,
      editable: true,
    },
    {
      field: "organ",
      headerName: "Organ",
      width: 175,
      editable: true,
    },
    {
      field: "department",
      headerName: "Department",
      width: 200,
      editable: true,
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      width: 200,
      editable: true,
    },
    {
      field: "uhid",
      headerName: "Patient UHID",
      width: 200,
      editable: true,
    },
    {
      field: "clinician",
      headerName: "Clinician",
      width: 200,
      editable: true,
    },
    {
      field: "slides",
      headerName: "Slides",
      width: 150,
      editable: true,
    },
    {
      field: "hospitalName",
      headerName: "Hospital",
      width: 200,
      editable: true,
    },
  ];

  const MuiTheme = createTheme({});

  return (
    <ThemeProvider theme={MuiTheme}>
      <Box width="100%" height={componentSize}>
        <DataGrid
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
            ".MuiDataGrid-menuList": {
              borderRadius: "10px",
            },
            ".MuiDataGrid-columnHeaders": {
              backgroundColor: "#e8e8eb",
            },
            ".MuiDataGrid-columnHeaders:hover": {
              backgroundColor: "#e8e8eb",
            },
            "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
              {
                outline: "none",
                cursor: "default",
              },
            ".MuiDataGrid-cell:hover": {
              cursor: "default !important",
            },
            "& .MuiDataGrid-menuIconButton": {
              visibility: "visible !important",
              ml: "-30px",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: "1 !important",
              visibility: "visible", // Setting opacity to 1 to keep the sort icon visible
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              opacity: "1 !important",
              visibility: "visible",
            },
          }}
          rows={filterData !== undefined ? filterData : []}
          // columns={[...columns, { field: "status", filterable: false }]}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          onRowClick={(e) => {
            sessionStorage.clear();
            navigate("/dashboard/case", {
              state: { caseId: e.id.split("-")[0] },
            });
          }}
          pageSizeOptions={[10]}
        />
      </Box>
    </ThemeProvider>
  );
};

export default CaseTable;
