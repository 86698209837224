import { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../DashboardLayout/Header";
import Loading from "../Loading/loading";
import {
  useGetUserInfoQuery,
  useUpdateFCMTokenMutation,
} from "../../state/API/HospitalApi";
import { requestFirebaseNotificationPermission } from "../../Firebase/init";

const DashboardLayout = () => {
  const { data: userInfo, isLoading } = useGetUserInfoQuery();
  const [updateFCMToken] = useUpdateFCMTokenMutation();
  const location = useLocation();

  const fcmToken = localStorage.getItem("fcm_token");

  useEffect(() => {
    if (fcmToken) return;
    requestFirebaseNotificationPermission({
      updateFCMToken,
    });
  }, [fcmToken, updateFCMToken]);

  if (isLoading) return <Loading />;

  return (
    <Flex
      w="100%"
      h="100vh"
      flexDirection="column"
      overflow="hidden !important"
    >
      {location.pathname === `/dashboard/${userInfo?.userType}` ? null : (
        <Header userInfo={userInfo} />
      )}

      <Flex w="100%" h="max(calc(100vh - 48px), calc(100vh - 48vh))">
        <Outlet />
      </Flex>
    </Flex>
  );
};

export default DashboardLayout;
