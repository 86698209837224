import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from "uuid";
import { HiOutlineArrowRight } from "react-icons/hi";
import moment from "moment";
import { AiOutlineSend } from "react-icons/ai";
import queriesSvg from "../../Static/Images/queries.svg";
import {
  CHAT_SUBSCRIPTION,
  FETCH_CONVERSATION,
  GET_QUERIES,
  QUERY_SUBSCRIPTION,
} from "../../state/API/graphql/ChatQuery";
import Loading from "../Loading/loading";
import ScrollBar from "../other/Scrollbar";
import QueryHelper from "./QueryHelper";

const Queries = ({ userInfo }) => {
  const { user } = useAuth0();
  const [queries, setQueries] = useState([]);
  const [groupMessages, setGroupMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [chatId, setChatId] = useState("");
  const [submitData, setSubmitData] = useState("");
  const notifications = true;
  const [
    fetchMessages,
    { loading: isConversationLoading, data: msgData, error },
  ] = useLazyQuery(FETCH_CONVERSATION);

  const {
    data: queriesData,
    loading,
    refetch,
  } = useQuery(GET_QUERIES, {
    variables: {
      query: {
        toId: user?.sub,
      },
    },
  });
  useEffect(() => {
    if (!queriesData) return;
    const queryData = queriesData?.readNotification?.data;
    const reversedQueries = queryData ? [...queryData]?.reverse() : [];
    setQueries(reversedQueries);
  }, [queriesData]);

  useEffect(() => {
    if (msgData && msgData.readChat.success) {
      const totalPages = msgData.readChat?.meta?.totalPages;
      if (totalPages) {
        setTotalPage(totalPages);
      }

      if (pageNumber === 1) setGroupMessages(msgData.readChat.data.reverse());
      else {
        const newData = [...msgData.readChat.data];
        setGroupMessages(newData.reverse().concat(groupMessages));
      }
    }
  }, [msgData]);

  useEffect(() => {
    if (pageNumber > 1) {
      fetchMessages({
        variables: {
          query: {
            filter: {
              toId: chatId,
            },
            paginate: {
              limit: 25,
              pageNumber,
            },
          },
        },
      });
    }
  }, [pageNumber]);

  useEffect(() => {
    setGroupMessages([]);

    fetchMessages({
      variables: {
        query: {
          filter: {
            toId: chatId,
          },
          paginate: {
            limit: 25,
            pageNumber: 1,
          },
        },
      },
      fetchPolicy: "no-cache",
    });
    setPageNumber(1);
    setTotalPage(1);
  }, [chatId, submitData]);
  const { data: subscribedQueryData } = useSubscription(QUERY_SUBSCRIPTION, {
    variables: {
      toId: user?.sub,
    },
  });
  useEffect(() => {
    if (!subscribedQueryData) return;
    setQueries((state) => [subscribedQueryData?.notifyChat, ...state]);
  }, [subscribedQueryData]);

  const { data: subscribedMessageData } = useSubscription(CHAT_SUBSCRIPTION, {
    variables: {
      toId: chatId,
      fromId: userInfo?._id,
    },
  });
  useEffect(() => {
    if (subscribedMessageData) {
      const newMessages = [
        ...groupMessages,
        subscribedMessageData.newChat.data,
      ];
      setGroupMessages(newMessages);
    }
  }, [subscribedMessageData]);
  const handleChatOpen = (Id) => {
    setChatId(Id);
  };
  const handleChatClose = () => {
    setChatId("");
    setMessageInput("");
  };

  return (
    <Box my="16px" h="fit-content" w="100%">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Flex
            alignItems="center"
            w="100%"
            justifyContent="flex-start"
            mb="8px"
            px="12px"
          >
            <Image src={queriesSvg} />
            <Text ml="5px" color="#3B5D7C">
              {queries?.length}
            </Text>
            <Text ml="5px" as="b">
              Queries
            </Text>
          </Flex>
          <Flex w="100%" h="160px" mx="12px" minH={0}>
            <ScrollBar>
              <Flex w="95%" alignItems="center" gap="2px" direction="column">
                {queries?.map((query) => (
                  <Flex
                    key={uuidv4() + query.chatId}
                    w="100%"
                    align="center"
                    justify="space-between"
                    bg="#fdfdfd"
                    p="0.6rem"
                    direction="column"
                    mb="0.6rem"
                    h="100%"
                  >
                    <Flex align="center" gap="10px">
                      <Text fontSize="14px" w="85%">
                        {`Dr. ${query.message}`}
                      </Text>
                      {chatId === query.chatId ? (
                        <IconButton
                          size="sm"
                          bg="none"
                          icon={
                            <ChevronUpIcon color="gray" w="22px" h="22px" />
                          }
                          onClick={() => handleChatClose()}
                        />
                      ) : (
                        <IconButton
                          size="sm"
                          bg="none"
                          icon={
                            <ChevronDownIcon color="gray" w="22px" h="22px" />
                          }
                          onClick={() => handleChatOpen(query.chatId)}
                        />
                      )}
                      {/* <CloseIcon w="12px" color="gray" h="12px" /> */}
                    </Flex>
                    <Flex w="100%">
                      {chatId === query.chatId && (
                        <Flex direction="column" w="100%">
                          <Text color="#999999" fontSize="12px">
                            {moment(query?.createdAt).format(
                              "HH:mm A DD-MM-YYYY"
                            )}
                          </Text>
                          <Text fontSize="14px" my="0.4rem">
                            {query.message1}
                          </Text>
                          {/* {isConversationLoading ? (
                            <Text my="0.3vh">fetching conversation...</Text>
                          ) : ( */}
                          <Flex
                            w="100%"
                            direction="column"
                            mb="0.6rem"
                            flexWrap="wrap"
                          >
                            {groupMessages?.map((userMessage) => {
                              return (
                                <Flex key={userMessage?._id} w="100%">
                                  <Text fontSize="14px">
                                    {userMessage.payload.body}
                                  </Text>
                                </Flex>
                              );
                            })}
                          </Flex>
                          {/* )} */}
                          <QueryHelper
                            userInfo={userInfo}
                            chatId={chatId}
                            id={query?._id}
                            groupMessages={groupMessages}
                            setGroupMessages={setGroupMessages}
                            refetch={refetch}
                            notifications={notifications}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </ScrollBar>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Queries;
