import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  HttpLink,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import Environment from "../../environment";

const ChatProvider = ({ children }) => {
  const token = localStorage.getItem(Environment.AUTH0_TOKEN);
  let accessToken;
  if (token) {
    const { body } = JSON.parse(token);
    if (body && typeof body === "object") {
      accessToken = body.access_token;
    }
  }
  const httpLink = new HttpLink({
    uri: "https://development-api.chat.prr.ai",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: "wss://development-api.chat.prr.ai",
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );
  const apolloClient = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ChatProvider;
