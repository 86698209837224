import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import Loading from "../Components/Loading/loading";
import { useGetUserInfoQuery } from "../state/API/HospitalApi";
import { PageNotFound, ServerDown } from "../Components/ErrorPages";

const RestrictedRoute = ({ children }) => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { error, isLoading } = useGetUserInfoQuery(
    2,
    {},
    {
      skip: !isAuthenticated,
    }
  );

  if (isAuthLoading || isLoading) return <Loading />;

  if (!isAuthenticated) return <Navigate to="/login" />;

  if (error && error.status === 404) return children;

  if (error && (error.status === "FETCH_ERROR" || error.status >= 500))
    return <ServerDown />;

  return (
    <Flex h="100vh">
      <PageNotFound />
    </Flex>
  );
};

export default RestrictedRoute;
