import React from "react";
import {
  Box,
  Text,
  HStack,
  Flex,
  VStack,
  useMediaQuery,
  Image,
} from "@chakra-ui/react";
import moment from "moment";
import { IoRadioButtonOnSharp } from "react-icons/io5";
import { getSlideUrl } from "../hooks/utility";

const Timeline = ({ caseInfoData, caseInfo }) => {
  const [ifWidthLessthan1920] = useMediaQuery("(max-width:1920px)");

  return (
    <Flex direction="column" bgColor="#FCFCFC">
      <Flex
        minH="2.812vh"
        fontSize={ifWidthLessthan1920 ? "14px" : " 0.72916vw"}
        p="1.65vh"
        paddingLeft={0}
        overflow="hidden"
        flexDir="column"
        boxShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
      >
        <VStack h="100%" alignItems="flex-start">
          <Flex>
            <VStack spacing={0}>
              <IoRadioButtonOnSharp
                color="#434343"
                size="0.9vw"
                opacity={0.6}
              />
              <Box h="100%" w="1.5px" bgColor="#434343" opacity={0.6} />
            </VStack>
            <Box
              ml="0.5vw"
              py="1.11vh"
              px="0.625vw"
              maxH="30%"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              <Text>
                {caseInfoData?.reported
                  ? `Case reported`
                  : "Case is not reported yet."}
              </Text>
            </Box>
          </Flex>
          {caseInfoData?.slides?.length > 0 && (
            <Flex>
              <VStack spacing={0}>
                <IoRadioButtonOnSharp
                  color="#434343"
                  size="0.9vw"
                  opacity={0.6}
                />
                <Box h="100%" w="1.5px" bgColor="#434343" opacity={0.6} />
              </VStack>
              <Box
                bgColor="#fff"
                ml="0.5vw"
                py="1.11vh"
                px="0.625vw"
                maxH="30%"
                overflow="auto"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#C4C4C4",
                  },
                }}
              >
                <Text>{`Uploded ${caseInfoData?.slides?.length}  slides`}</Text>
                {caseInfo?.slides?.map((slide, index) => {
                  return (
                    slide?.uploadedAt && (
                      <Flex mt="1vh" key={slide._id}>
                        <Image
                          src={getSlideUrl(slide?.awsImageBucketUrl)}
                          w="1.458vw"
                          h="2.592vh"
                          mr="0.6vw"
                          mt="0.7vh"
                        />
                        <Text>{`${caseInfoData?.caseName}-${
                          index + 1
                        } Uploaded at ${moment(slide?.uploadedAt).format(
                          "DD.MM.YYYY-h:mm:ss "
                        )} by ${slide?.uploadedBy?.firstName} ${
                          slide?.uploadedBy?.lastName
                        }`}</Text>
                      </Flex>
                    )
                  );
                })}
              </Box>
            </Flex>
          )}
          <Flex>
            <VStack spacing={0}>
              <IoRadioButtonOnSharp
                color="#434343"
                size="0.9vw"
                opacity={0.6}
              />
              <Box h="100%" w="1.5px" bgColor="#434343" opacity={0.6} />
            </VStack>
            <Box
              bgColor="#fff"
              ml="0.5vw"
              py="1.11vh"
              px="0.625vw"
              maxH="30%"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              {caseInfoData?.gross?.grossImageURLs?.length > 0 && (
                <>
                  <Text>
                    {`Uploaded ${caseInfoData?.gross?.grossImageURLs.length} Gross
                      Images by ${caseInfoData?.gross?.uploadedBy?.firstName} 
                      ${caseInfoData?.gross?.uploadedBy?.lastName}`}
                  </Text>
                  <VStack mt="1.5vw">
                    {caseInfoData?.gross?.grossImageURLs?.map((url, i) => {
                      return (
                        <HStack alignItems="center" key={url}>
                          <Image src={url} w="2vw" />
                          <VStack alignItems="flex-start">
                            <Text>{`Gross Image ${i + 1} Uploaded at ${moment(
                              caseInfoData?.caseCreationDate
                            ).format("DD.MM.2022-h:mm:ss")}`}</Text>
                            {/* <Text></Text> */}
                          </VStack>
                        </HStack>
                      );
                    })}
                  </VStack>
                </>
              )}
            </Box>
          </Flex>
          <Flex>
            <VStack spacing={0}>
              <IoRadioButtonOnSharp
                color="#434343"
                size="0.9vw"
                opacity={0.6}
              />
              <Box h="100%" w="1.5px" bgColor="#434343" opacity={0.6} />
            </VStack>
            <Box
              bgColor="#fff"
              py="1.11vh"
              px="0.625vw"
              maxH="25%"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
                },
              }}
            >
              <VStack alignItems="flex-start">
                {caseInfoData?.gross?.grossCreationDate && (
                  <Text>
                    {`Instruction for Grossing at ${moment(
                      caseInfoData?.caseCreationDate
                    ).format("DD.MM.YYYY-h:mm:ss")} by 
                      ${caseInfoData?.gross?.uploadedBy?.firstName} ${
                      caseInfoData?.gross?.uploadedBy?.lastName
                    }`}
                  </Text>
                )}

                <Text>
                  Description:
                  {caseInfoData?.gross?.grossDescription
                    ? caseInfoData?.gross?.grossDescription
                    : " No Description"}
                </Text>
              </VStack>
            </Box>
          </Flex>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default Timeline;
